import { render, staticRenderFns } from "./SimilarArts.vue?vue&type=template&id=7772b255&"
import script from "./SimilarArts.vue?vue&type=script&lang=js&"
export * from "./SimilarArts.vue?vue&type=script&lang=js&"
import style0 from "./SimilarArts.vue?vue&type=style&index=0&id=7772b255&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports