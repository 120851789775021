const state = {
  open: false
};

const getters = {};

const actions = {
  toggle(context) {
    context.commit("toggle");
  }
};

const mutations = {
  toggle(state) {
    console.log(state);
    state.open = !state.open;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
