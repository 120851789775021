import * as API from "@/services/API";

export default {
  getReturnPolicyField() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/return-policy-get-fields`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
