import menu from "./menu";
import * as auth from "./Auth";
import * as image from "./Image";
import * as order from "./Order";
import * as cart from "./Cart";
import * as snackbar from "./Snackbar";
import * as homepage from "./HomepageFields";
import * as homeCategories from "./HomepageCategories";
import * as homeSlider from "./HomepageSlider";
import * as loader from "./loader";
import * as faq from "./FAQFields";
import * as faqAccordion from "./FAQAccordion";
import * as artistSales from "./ArtistSales";
import * as categoriesList from "./CategoriesList";

export default {
  menu,
  auth,
  image,
  cart,
  snackbar,
  order,
  homepage,
  homeSlider,
  homeCategories,
  loader,
  faq,
  faqAccordion,
  artistSales,
  categoriesList,
};
