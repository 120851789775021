import * as API from "@/services/API";

export default {
  addArtistBanner(payload) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .post("/profile-background", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addArtistAvatar(payload) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .post("/profile-image ", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteArtistBanner() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .delete(`/profile-background`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteArtistAvatar() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .delete("/profile-image")
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
