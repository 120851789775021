<template>
  <div class="aa-type-of-paper__slider">
    <div class="aa-type-of-paper__slider-heading">
      <h2>Similar Arts</h2>
      <router-link to="/art-categories" class="btn btn-primary__circle">
        View All
      </router-link>
    </div>
    <carousel
      :autoplaySpeed="3000"
      :autoWidth="true"
      :autoplay="true"
      :margin="30"
      :dots="false"
      :loop="true"
      :items="4"
      :nav="false"
      :responsive="{
        320: { items: 1, autoWidth: false, center: true, dots: false },
        400: { items: 2, dots: true, center: true },
        768: { items: 4, margin: 20 },
        1200: { items: 4 },
      }"
    >
      <div
        class="aa-type-of-paper__slider-item"
        v-for="(slide, index) in slides"
        :key="index"
      >
        <router-link :to="`order?image_id=${slide.id}&mode=create`">
          <img
            :src="
              slide.preview_image.path
                ? slide.preview_image.path
                : '/images/placehold.webp'
            "
            :alt="slide.name"
        /></router-link>
        <h4>
          <router-link :to="`order?image_id=${slide.id}&mode=create`">
            {{ slide.name }}
          </router-link>
        </h4>
      </div>

      <template slot="prev">
        <span hidden ref="prev" class="prev">prev</span>
      </template>

      <template slot="next">
        <span hidden ref="next" class="next">next</span>
      </template>
    </carousel>
  </div>
</template>

<script>
import carousel from "v-owl-carousel";

export default {
  data: () => {
    return {
      sliderItems: [],
    };
  },
  props: ["slides"],
  // watch: {
  //   slides: {
  //     immediate: true,
  //     deep: true,
  //     handler(e) {
  //       this.sliderItems = e;
  //     },
  //   },
  // },
  components: { carousel },
};
</script>

<style lang="scss">
@import "./style-similar-arts.scss";
</style>
