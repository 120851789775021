<template>
  <component :is="layout">
    <Loader />
    <router-view />
  </component>
</template>

<script>
import Footer from "./components/Footer/Footer.vue";
import MainMenu from "./components/MainMenu.vue";
import Loader from "./components/Loader.vue";

export default {
  components: { "aa-footer": Footer, MainMenu, Loader },
  data: () => ({
    drawer: false,
    isPreload: true,
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    },
  },
  methods: {},
  updated: function() {
    this.$nextTick(function() {
      // console.log("End loading new");
      document.querySelector("html").classList.remove("on-scroll");
    });
  },
};
</script>
