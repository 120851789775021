<template>
  <v-dialog
    :value="id !== null"
    persistent
    max-width="600px"
    scrollable
    class="aa-edit-details-main"
  >
    <div :loading="loading" class="aa-edit-details">
      <v-img
        :src="preview_image ? preview_image.path : ''"
        height="310px"
        width="540px"
        contain
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="red"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <v-text-field
        placeholder="Name"
        v-model="name"
        :disabled="loading"
        hide-details="auto"
        solo
        flat
      />

      <v-select
        :items="proportionItems"
        v-model="proportion"
        placeholder="Proportion"
        solo
        flat
        hide-details="auto"
      ></v-select>

      <v-select
        :items="styleItems"
        v-model="style"
        placeholder="Style"
        solo
        flat
        hide-details="auto"
      ></v-select>

      <v-select
        :items="nameFilter"
        v-model="subject"
        placeholder="Subject"
        solo
        flat
        hide-details="auto"
      ></v-select>

      <v-select
        :items="mediumItems"
        v-model="medium"
        placeholder="Medium"
        solo
        flat
        hide-details="auto"
      ></v-select>

      <div class="aa-edit-details__size">
        <v-text-field
          v-model.number="original_height"
          placeholder="Original Height"
          suffix="in"
          :disabled="loading"
          solo
          flat
          :messages="'in inches'"
          hide-spin-buttons
          type="number"
        />

        <v-text-field
          v-model.number="original_width"
          placeholder="Original Width"
          suffix="in"
          :disabled="loading"
          hide-details="auto"
          solo
          flat
          hide-spin-buttons
          type="number"
        />
      </div>

      <div class="aa-edit-details__action">
        <div class="aa-edit-details__date">
          <svg
            width="30"
            height="35"
            viewBox="0 0 30 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M0 10.5C0 6.81814 2.98477 3.83337 6.66667 3.83337H23.3333C27.0152 3.83337 30 6.81814 30 10.5V13V28C30 31.6819 27.0152 34.6667 23.3333 34.6667H6.66667C2.98477 34.6667 0 31.6819 0 28V13V10.5Z"
              fill="#28303F"
            />
            <path
              d="M23.3333 3.83337H6.66667C2.98477 3.83337 0 6.81814 0 10.5V13H30V10.5C30 6.81814 27.0152 3.83337 23.3333 3.83337Z"
              fill="#28303F"
            />
            <path
              opacity="0.4"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.33398 0.083374C9.02434 0.083374 9.58398 0.643018 9.58398 1.33337V6.33337C9.58398 7.02373 9.02434 7.58337 8.33398 7.58337C7.64363 7.58337 7.08398 7.02373 7.08398 6.33337V1.33337C7.08398 0.643018 7.64363 0.083374 8.33398 0.083374ZM21.6673 0.083374C22.3577 0.083374 22.9173 0.643018 22.9173 1.33337V6.33337C22.9173 7.02373 22.3577 7.58337 21.6673 7.58337C20.977 7.58337 20.4173 7.02373 20.4173 6.33337V1.33337C20.4173 0.643018 20.977 0.083374 21.6673 0.083374Z"
              fill="#28303F"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.8232 18.726C21.3427 19.1806 21.3954 19.9704 20.9408 20.4899L16.1576 25.9564C15.1298 27.131 13.3594 27.2883 12.1405 26.3133L9.21918 23.9762C8.6801 23.5449 8.5927 22.7583 9.02396 22.2192C9.45522 21.6802 10.2418 21.5928 10.7809 22.024L13.7023 24.3611C13.8764 24.5004 14.1293 24.4779 14.2761 24.3101L19.0593 18.8436C19.5139 18.3241 20.3036 18.2714 20.8232 18.726Z"
              fill="#28303F"
            />
          </svg>
          <v-menu
            ref="dateScannedMenu"
            v-model="dateScannedMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :nudge-right="-70"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_scanned_formatted"
                hint="MM/DD/YYYY format"
                persistent-hint
                v-bind="attrs"
                v-on="on"
                @blur="date = parseDate(dateFormatted)"
                solo
                flat
                :disabled="loading"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_scanned"
              :max="new Date().toISOString().substr(0, 10)"
              no-title
              scrollable
              @input="dateScannedMenu = false"
              color="red"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                class="btn-secondary arrow"
                @click="dateScannedMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                class="btn btn-primary"
                @click="$refs.dateScannedMenu.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="aa-edit-details__btn">
          <span class="aa-edit-details__btn-close" @click="closeDialog">
            &#215;
          </span>
          <button class="btn-secondary arrow" @click="closeDialog" text>
            Close
          </button>
          <v-btn
            class="btn btn-primary arrow"
            @click="save"
            :disabled="loading"
            :loading="loading"
          >
            Save
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ImageService from "@/services/ImageService";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "ImageEdit",
  mounted: async function() {
    const image = await ImageService.getImage(this.id);
    if (image.date_scanned) {
      this.date_scanned = new Date(image.date_scanned)
        .toISOString()
        .substring(0, 10);
    }
    this.original_height = image.original_height;
    this.original_width = image.original_width;
    this.name = image.name;
    this.preview_image = image.preview_image;
    this.proportion = image.proportion;
    this.style = image.style;
    this.subject = image.ImageCategory ? image.ImageCategory.name : null;
    this.medium = image.medium;

    this.loading = false;
  },
  created: function() {
    this.$store.dispatch("categoriesList/getField");
  },
  computed: {
    ...mapGetters("categoriesList", ["error", "field"]),
    nameFilter: function() {
      var arr = [];
      this.field.forEach(function(e) {
        arr.push(e.name);
      });
      return arr;
    },
    listFilter: function() {
      return this.field;
    },
    date_scanned_formatted: function() {
      if (!this.date_scanned) {
        return null;
      }

      return moment(this.date_scanned).format("MM/DD/YYYY");
    },
  },
  methods: {
    closeDialog: function() {
      this.$emit("close-edit-dialog");
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async save() {
      let subjectArr = this.field;
      let subjectCurr = this.subject;

      subjectArr.filter(function(obj) {
        if (obj.name == subjectCurr) {
          subjectCurr = obj.id;
        }
      });

      try {
        this.loading = true;
        const payload = {
          date_scanned: this.date_scanned,
          original_height: this.original_height,
          original_width: this.original_width,
          proportion: this.proportion,
          style: this.style,
          subject: subjectCurr,
          medium: this.medium,
          name: this.name,
        };

        await ImageService.putImage(this.id, payload);
        this.loading = false;
        this.$emit("updated-image");
      } catch (error) {
        console.error(error);
      }
    },
  },
  props: {
    id: Number,
  },
  data: () => {
    return {
      loading: true,
      dateScannedMenu: false,
      date_scanned: null,
      original_height: null,
      original_width: null,
      preview_image: null,
      name: null,
      proportion: null,
      style: null,
      subject: null,
      medium: null,
      proportionItems: ["Landscape", "Portrait", "Square"],
      styleItems: ["Traditional", "Modern", "Decorative", "Abstract"],
      subjectItems: ["Figurative", "Landscape", "Still Life", "Floral"],
      mediumItems: [
        "Oil",
        "Acrylic",
        "Watercolor",
        "Pastel",
        "Charcoal",
        "Mixed Media",
      ],
    };
  },
};
</script>

<style lang="scss">
@import "./style-edit.scss";
</style>
