<template>
  <div class="aa-checkout ">
    <v-container>
      <div class="aa-checkout__heading">
        <h1>
          Checkout
        </h1>
        <additional-products-dialog v-show="this.authUser" />
      </div>

      <div v-if="!this.localDataItems && !this.cartFilterLength">
        You have no items in your cart. Please return to
        <router-link
          to="/art-categories"
          style="color:var(--red); text-decoration: underline;"
          >Art Categories</router-link
        >
        to add items to your cart.
      </div>

      <div
        v-if="this.cartFilterLength || this.localDataItems"
        class="aa-checkout__main"
      >
        <div class="aa-checkout__main-items">
          <div class="aa-checkout__main-items-length">
            {{ cart.total_items || this.localDataQuanty }} item<span
              v-if="cart.total_items !== 1"
              >s
            </span>
          </div>
          <cart-item-card
            @events="isFlag"
            :item="item"
            v-for="item in cart.cart_items || this.localDataItems"
            :key="item.id"
          />
        </div>

        <div class="aa-checkout__main-info">
          <div
            class="aa-checkout__main-tabs"
            v-show="!this.authUser && this.localDataItems"
          >
            <v-alert dense outlined class="aa-mark">
              Please login or register to add the shipping/billing information.
            </v-alert>

            <v-tabs v-model="tab">
              <v-tab href="#login" :ripple="false" :disabled="this.loading">
                Log In
              </v-tab>

              <v-tab href="#signup" :ripple="false" :disabled="this.loading">
                Register
              </v-tab>

              <v-tab style="display: none" href="#forgot-password"></v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="login">
                <LoginForm
                  :isReset="isReset"
                  :isFlagPath="isFlagPath"
                  @upDataAuth="upDataAuthFlag"
                />
              </v-tab-item>

              <v-tab-item value="signup">
                <registration-form
                  :isFlagPath="isFlagPath"
                  @upDataAuth="upDataAuthFlag"
                />
              </v-tab-item>

              <v-tab-item value="forgot-password">
                <forgot-password-form />
              </v-tab-item>
            </v-tabs-items>
          </div>

          <div
            class="aa-checkout__main-info-wrap "
            :class="{ disabled: !this.authUser && this.localDataItems }"
          >
            <v-form v-model="formValid" ref="form" lazy-validation>
              <v-card flat>
                <h3>Shipping Address</h3>
                <div class="aa-checkout__main-info-shipping">
                  <label>
                    <h4>Address Line 1</h4>
                    <v-text-field
                      type="text"
                      placeholder="Address Line 1"
                      v-model="shipping_address_line_1"
                      :rules="validationRules.required"
                      solo
                      flat
                      hide-details="auto"
                    />
                  </label>

                  <label>
                    <h4>Address Line 2</h4>
                    <v-text-field
                      type="text"
                      placeholder="Address Line 2"
                      v-model="shipping_address_line_2"
                      solo
                      flat
                      hide-details="auto"
                    />
                  </label>

                  <label class="flex-6">
                    <h4>City</h4>
                    <v-text-field
                      type="text"
                      placeholder="City"
                      v-model="shipping_address_city"
                      :rules="validationRules.required"
                      solo
                      flat
                      hide-details="auto"
                    />
                  </label>

                  <label class="flex-6">
                    <h4>State</h4>
                    <v-text-field
                      type="text"
                      placeholder="State"
                      v-model="shipping_address_state"
                      :rules="validationRules.required"
                      solo
                      flat
                      hide-details="auto"
                  /></label>

                  <label>
                    <h4>Postal Code</h4>
                    <v-text-field
                      type="text"
                      placeholder="Postal Code"
                      v-model="shipping_address_postal_code"
                      :rules="validationRules.required"
                      solo
                      flat
                      hide-details="auto"
                  /></label>
                </div>
              </v-card>
              <v-card flat>
                <h3>Billing Address</h3>
                <v-checkbox
                  v-model="shipping_is_billing"
                  label="Same as Shipping"
                  hide-details="auto"
                  :ripple="false"
                  color="red"
                  style="margin-bottom: 20px;"
                ></v-checkbox>
                <div
                  class="aa-checkout__main-info-billing"
                  v-if="!shipping_is_billing"
                >
                  <label>
                    <h4>Address Line 1</h4>
                    <v-text-field
                      type="text"
                      placeholder="Address Line 1"
                      v-model="billing_address_line_1"
                      :rules="validationRules.requiredIfNotSame"
                      solo
                      flat
                      hide-details="auto"
                  /></label>

                  <label>
                    <h4>Address Line 2</h4>
                    <v-text-field
                      type="text"
                      placeholder="Address Line 2"
                      v-model="billing_address_line_2"
                      solo
                      flat
                      hide-details="auto"
                    />
                  </label>

                  <label class="flex-6">
                    <h4>City</h4>
                    <v-text-field
                      type="text"
                      placeholder="City"
                      v-model="billing_address_city"
                      solo
                      flat
                      hide-details="auto"
                      :rules="validationRules.requiredIfNotSame"
                    />
                  </label>

                  <label class="flex-6">
                    <h4>State</h4>
                    <v-text-field
                      type="text"
                      placeholder="State"
                      v-model="billing_address_state"
                      solo
                      flat
                      hide-details="auto"
                      :rules="validationRules.requiredIfNotSame"
                    />
                  </label>

                  <label>
                    <h4>Postal Code</h4>
                    <v-text-field
                      type="text"
                      placeholder="Postal Code"
                      v-model="billing_address_postal_code"
                      solo
                      flat
                      hide-details="auto"
                      :rules="validationRules.requiredIfNotSame"
                    />
                  </label>
                </div>
              </v-card>
              <v-card flat>
                <h3>Additional Instructions</h3>
                <label>
                  <p class="ma-0" style="color: #000000;">
                    Enter any additional instructions if required
                  </p>
                  <v-textarea
                    type="text"
                    v-model="additional_instructions"
                    rows="3"
                    solo
                    flat
                    hide-details="auto"
                    class="aa-checkout__additional-instructions"
                  />
                </label>
              </v-card>
              <v-card flat class="aa-checkout__subtotal">
                <div class="aa-checkout__subtotal-alert">
                  <span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        opacity="0.4"
                        cx="12"
                        cy="12"
                        r="11.6667"
                        fill="black"
                      />
                      <path
                        d="M10.8333 17.8333C10.8333 17.189 11.3557 16.6667 12 16.6667C12.6443 16.6667 13.1667 17.189 13.1667 17.8333C13.1667 18.4777 12.6443 19 12 19C11.3557 19 10.8333 18.4777 10.8333 17.8333Z"
                        fill="black"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 15.2083C11.5168 15.2083 11.125 14.8166 11.125 14.3333L11.125 6.16667C11.125 5.68342 11.5168 5.29167 12 5.29167C12.4832 5.29167 12.875 5.68342 12.875 6.16667L12.875 14.3333C12.875 14.8166 12.4832 15.2083 12 15.2083Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  The price below is an estimate only. It does not include
                  shipping. A final invoice will be emailed to you.
                </div>

                <div
                  class="aa-checkout__subtotal-discount"
                  v-if="cart.discount_total"
                >
                  <div>
                    <h4>Product Total</h4>
                    <span class="price">{{
                      cart.product_total | currency
                    }}</span>
                  </div>
                  <div>
                    <h4>Discount</h4>
                    <span class="price"
                      >-{{ cart.discount_total | currency }}
                    </span>
                  </div>
                </div>

                <div class="aa-checkout__subtotal-price">
                  <div>
                    <h4>Subtotal</h4>
                    <span class="price">
                      {{
                        cart.grand_total
                          ? cart.grand_total
                          : localDataSubtotal | currency
                      }}
                    </span>
                  </div>
                  <v-btn
                    @click="submit"
                    :loading="loading"
                    class="btn btn-primary arrow"
                  >
                    Submit Order
                  </v-btn>
                </div>
              </v-card>
            </v-form>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LoginForm from "@/components/Form/LoginForm.vue";
import RegistrationForm from "@/components/Form/RegistrationForm.vue";
import ForgotPasswordForm from "@/components/Form/ForgotPasswordForm.vue";
import CartItemCard from "@/components/ToolsBar/CartItemCard.vue";
import OrderService from "@/services/OrderService";
import AdditionalProductsDialog from "@/components/ToolsBar/AdditionalProductsDialog.vue";
export default {
  name: "Checkout",
  components: {
    CartItemCard,
    AdditionalProductsDialog,
    LoginForm,
    RegistrationForm,
    ForgotPasswordForm,
  },
  mounted: async function() {
    await this.$store.dispatch("cart/getCart");

    if (
      this.authUser ||
      window.localStorage.getItem("guest") === "isNotGuest"
    ) {
      this.upDataAuthFlag();
    } else {
      const dataGuest = await JSON.parse(localStorage.getItem("guestCart"));

      this.localDataItems = dataGuest.cart_items;
      this.localDataSubtotal = dataGuest.product_total;
      this.localDataQuanty = dataGuest.total_items;
    }
  },
  data: () => {
    return {
      isFlagPath: true,
      isReset: false,
      tab: null,
      localDataSubtotal: 0,
      localDataQuanty: 0,
      localDataItems: null,
      shipping_is_billing: true,
      formValid: true,
      loading: false,
      shipping_address_line_1: null,
      shipping_address_line_2: null,
      shipping_address_city: null,
      shipping_address_state: null,
      shipping_address_postal_code: null,
      shipping_address_country: null,
      billing_address_line_1: null,
      billing_address_line_2: null,
      billing_address_city: null,
      billing_address_state: null,
      billing_address_postal_code: null,
      billing_address_country: null,
      additional_instructions: null,
      countries: [
        { value: "US", text: "United States" },
        { value: "CN", text: "Canada" },
      ],
    };
  },
  methods: {
    upDataAuthFlag: async function() {
      await this.$store.dispatch("auth/getAuthUser").then((user) => {
        this.shipping_address_line_1 = user.shipping_address_line_1;
        this.shipping_address_line_2 = user.shipping_address_line_2;
        this.shipping_address_city = user.shipping_address_city;
        this.shipping_address_state = user.shipping_address_state;
        this.shipping_address_postal_code = user.shipping_address_postal_code;
        if (user.shipping_address_country) {
          this.shipping_address_country = this.countries.find((country) => {
            return country.value === user.shipping_address_country;
          });
        }
        this.billing_address_line_1 = user.billing_address_line_1;
        this.billing_address_line_2 = user.billing_address_line_2;
        this.billing_address_city = user.billing_address_city;
        this.billing_address_state = user.billing_address_state;
        this.billing_address_postal_code = user.billing_address_postal_code;
        if (user.billing_address_country) {
          this.billing_address_country = this.countries.find((country) => {
            return country.value === user.billing_address_country;
          });
        }
      });
    },
    isFlag: async function() {
      const dataGuest = await JSON.parse(
        localStorage.getItem("guestCart") || "[]"
      );

      this.localDataItems = dataGuest.cart_items;
      this.localDataSubtotal = dataGuest.product_total;
      this.localDataQuanty = dataGuest.total_items;
    },
    submit: function() {
      const valid = this.$refs.form.validate();
      if (valid) {
        // submit the actual form
        OrderService.addOrder({
          shipping_is_billing: this.shipping_is_billing,
          shipping_address_line_1: this.shipping_address_line_1,
          shipping_address_line_2: this.shipping_address_line_2,
          shipping_address_city: this.shipping_address_city,
          shipping_address_state: this.shipping_address_state,
          shipping_address_postal_code: this.shipping_address_postal_code,
          shipping_address_country: this.shipping_address_country,
          billing_address_line_1: this.billing_address_line_1,
          billing_address_line_2: this.billing_address_line_2,
          billing_address_city: this.billing_address_city,
          billing_address_state: this.billing_address_state,
          billing_address_postal_code: this.billing_address_postal_code,
          billing_address_country: this.billing_address_country,
          additional_instructions: this.additional_instructions,
          order_items: this.cart.cart_items,
        })
          .then((response) => {
            console.log(response.order);
            this.$store.dispatch("snackbar/showSnack", {
              text: "Order submitted successfully.",
              color: "success",
              timeout: 2000,
            });
            this.$store.dispatch("cart/getCart");
            this.$router.push({
              name: "ThankYou",
              params: { order: response.order },
            });
          })
          .catch((err) => {
            console.error(err);
            this.$store.dispatch("snackbar/showSnack", {
              text:
                "There was an error submitting order. Please try again later or contact support.",
              color: "error",
              timeout: 3500,
            });
          });
      }
    },
  },
  computed: {
    ...mapGetters("cart", ["error", "cart"]),
    ...mapGetters("auth", ["error", "authUser"]),
    cartData() {
      if (this.cart) {
        return this.cart;
      }
      return false;
    },
    validationRules: function() {
      const rules = {};
      rules.required = [(v) => !!v || "This field is required."];
      rules.requiredIfNotSame = [];
      if (!this.shipping_is_billing) {
        rules.requiredIfNotSame.push((v) => !!v || "This field is required.");
      }
      return rules;
    },
    cartFilterLength: function() {
      if (this.cart.cart_items && this.cart.cart_items.length == 0) {
        console.log("checkout empty");
        return this.cart.cart_items.length;
      }

      return this.authUser ? true : false;
    },
  },
  filters: {
    currency: function(value) {
      if (!value) return "-";
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
  },
};
</script>

<style lang="scss">
@import "./style-checkout.scss";
</style>
