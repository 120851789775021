import { getError } from "@/utils/helpers";
import ArtistSalesService from "@/services/ArtistSalesService";

export const namespaced = true;

function setFields(commit, response) {
  commit("SET_FIELD", response.data);
  commit("SET_LOADING", false);
}

export const state = {
  field: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_FIELD(state, field) {
    state.field = field;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getField({ commit }) {
    commit("SET_LOADING", true);
    ArtistSalesService.getField()
      .then((response) => {
        setFields(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
};

export const getters = {
  field: (state) => {
    return state.field;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
