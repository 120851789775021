<template>
  <section class="aa-printing-card container-out">
    <v-container>
      <div class="aa-printing-card__wrapper">
        <div class="aa-printing-card__image">
          <v-img
            :alt="filteredField.third_section_title"
            :src="
              filteredField.third_section_image
                ? $func.src(filteredField.third_section_image)
                : '/images/placehold.webp'
            "
            lazy-src="/images/placehold.webp"
            content-class="aa-printing-card__image-wrap"
            max-height="320"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="red"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
        <div class="aa-printing-card__content">
          <h2 class="aa-printing-card__title">
            {{ filteredField.third_section_title }}
          </h2>
          <p class="aa-printing-card__des">
            {{ filteredField.third_section_description }}
          </p>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters("homepage", ["error", "loading", "field"]),
    filteredField: function() {
      if (!this.field || this.field === "") {
        return this.field;
      }
      return this.field;
    },
    filteredLoading: function() {
      if (!this.loading || this.loading === "") {
        return this.loading;
      }
      return this.loading;
    },
  },
};
</script>

<style lang="scss">
@import "./printing-card-style";
</style>
