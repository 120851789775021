<template>
  <div class="aa-my-account__sales">
    <div class="aa-my-account__sales-title" v-if="!loading">
      <div>Item</div>
      <div>Artwork size</div>
      <div>Amount sold</div>
      <div>Total</div>
      <div>Order Date</div>
    </div>

    <div class="aa-my-account__sales-body" v-if="!loading && !this.pageLoading">
      <div
        class="aa-my-account__sales-item"
        v-for="(item, i) in this.pageData"
        :key="i"
      >
        <div class="aa-my-account__sales-id">
          <span>Item</span>
          <div>
            <v-img
              :src="
                item.path
                  ? $func.src(item.path, true)
                  : '/images/placehold.webp'
              "
              :lazy-src="'/images/placehold.webp'"
              aspect-ratio="1.2"
              cover
              max-height="38"
              max-width="38"
              :alt="item.name"
            ></v-img>
            {{ item.id }}
          </div>
        </div>
        <div class="aa-my-account__sales-size">
          <span>Artwork size</span>
          <div v-if="item.original_width && item.original_height">
            {{ item.original_width }}x{{ item.original_height }}
            in
          </div>
          <div v-else v-text="'----'"></div>
        </div>
        <div><span>Amount sold</span>{{ item.quantity }}</div>
        <div><span>Total</span>${{ item.total }}</div>
        <div><span>Order Date</span>{{ dateFormatted(item.created_at) }}</div>
      </div>
    </div>

    <v-row
      class="fill-height pa-md-16"
      align="center"
      justify="center"
      v-if="this.pageLoading || this.loading"
    >
      <v-progress-circular indeterminate color="red" :size="100" :width="10"
        >Loading</v-progress-circular
      >
    </v-row>

    <div class="v-pagination__inner" v-if="!loading && this.pageLength > 1">
      <v-pagination
        v-model="page"
        :length="2"
        @input="gatPaginationPage"
        :total-visible="6"
        color="none"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ArtistSalesService from "@/services/ArtistSalesService";
// import ImageData from "@/assets/image.js";
import moment from "moment";

export default {
  data: () => {
    return {
      imageData: null,
      image: "das",
      page: 1,
      pages: null,
      pageDataLength: null,
      pageCurrent: null,
      pageData: null,
      pageLength: null,
      pageLoading: false,
    };
  },
  created: async function() {
    this.$store.dispatch("artistSales/getField");
  },
  computed: {
    ...mapGetters("artistSales", ["error", "loading", "field"]),
    filteredField: function() {
      if (!this.field || this.field === "") {
        return this.field.data;
      }
      return this.field.data;
    },
    filteredLoading: function() {
      if (!this.loading || this.loading === "") {
        return this.loading;
      }
      return this.loading;
    },
  },
  methods: {
    async gatPaginationPage() {
      this.pageLoading = true;

      this.$emit("outside-sales", {
        page: this.page,
        pageLength: this.pageLength,
        pageLoading: this.pageLoading,
      });
      try {
        const response = await ArtistSalesService.getCurrentPage(this.page);
        this.pageData = response.data;
        this.pageDataLength = response.data.length;
        this.pageCurrent = response.data;

        this.pageLoading = false;
        this.$emit("outside-sales", {
          page: this.page,
          pageLength: this.pageLength,
          pageLoading: this.pageLoading,
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    dateFormatted(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    inchConvert(el) {
      const num = el / 93;
      return num.toFixed(1).replace(/\.?0*$/, "");
    },
  },
  mounted: async function() {
    try {
      this.pages = await ArtistSalesService.getCurrentPage(this.page);
      if (this.pages) {
        this.pageData = this.pages.data;
        this.pageDataLength = this.pages.data.length;
        this.pageCurrent = this.pages.data;
        this.pageLength = this.pages.last_page;
      }
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style lang="scss">
@import "./sales-style.scss";
</style>
