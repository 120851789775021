import * as API from "@/services/API";

export default {
  getImages() {
    return API.apiClient.get("/images");
  },
  getImage(id) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/images/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getImagePagination(page) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/images?page=${page}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getImageCategory(id) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/get-related-images?image_category_id=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getImageSearch(page, search, order) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(
          `/artist-search-images?page=${page}&search=${search}&order_by=${order}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  downloadImage(id, query) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/images/${id}/download`, { params: query })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getImageData(id) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/images/${id}/base64`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  putImage(id, payload) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .put(`/images/${id}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteImage(id) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .delete(`/images/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
