<template>
  <aside class="aa-art-categories__sidebar" :class="{ active: this.drawer }">
    <v-layout class="aa-art-categories__sidebar-buttons">
      <button class="btn btn-primary arrow" @click="cleanSidebar">Clear</button>
      <button
        class="aa-art-categories__sidebar-close btn-secondary arrow "
        @click="closeSidebar"
      >
        Close
      </button>
    </v-layout>
    <div>
      <h4>{{ this.medium.title }}</h4>
      <div class="aa-art-categories__sidebar-checkbox">
        <v-checkbox
          v-for="(i, index) in medium.items"
          :key="i.items"
          v-model="selectedMedium"
          :value="medium.items[index]"
          @change="isChecked($event)"
          :label="medium.items[index]"
          class="pa-0 ma-0"
          color="red"
          :ripple="false"
          hide-details="auto"
        ></v-checkbox>
      </div>
    </div>

    <div>
      <!-- {{ this.selectedProportion }} -->
      <h4>{{ this.proportion.title }}</h4>
      <div class="aa-art-categories__sidebar-checkbox">
        <v-checkbox
          v-for="(i, index) in proportion.items"
          :key="i.items"
          v-model="selectedProportion"
          :value="proportion.items[index]"
          @change="isChecked($event)"
          :label="proportion.items[index]"
          class="pa-0 ma-0"
          color="red"
          :ripple="false"
          hide-details="auto"
        ></v-checkbox>
      </div>
    </div>

    <div>
      <!-- {{ this.selectedStyle }} -->
      <h4>{{ this.style.title }}</h4>
      <div class="aa-art-categories__sidebar-checkbox">
        <v-checkbox
          v-for="(i, index) in style.items"
          :key="i.items"
          v-model="selectedStyle"
          :value="style.items[index]"
          @change="isChecked($event)"
          :label="style.items[index]"
          class="pa-0 ma-0"
          color="red"
          :ripple="false"
          hide-details="auto"
        ></v-checkbox>
      </div>
    </div>

    <div v-if="!this.loading">
      <!-- {{ this.selectedSubject }} -->
      <h4>{{ this.subject.title }}</h4>
      <div class="aa-art-categories__sidebar-checkbox">
        <v-checkbox
          v-for="i in listFilter"
          :key="i.name"
          v-model="selectedSubject"
          :value="i.id"
          @change="isChecked($event)"
          :label="i.name"
          class="pa-0 ma-0"
          color="red"
          :ripple="false"
          :disabled="loading"
          :loading="loading"
          hide-details="auto"
        ></v-checkbox>
      </div>
    </div>
    <button
      style="width: 100%;"
      class="btn btn-primary arrow aa-art-categories__sidebar-clean"
      @click="cleanSidebar"
    >
      Clear
    </button>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    drawer: null,
    checkbox: false,
    selectedMedium: [],
    selectedProportion: [],
    selectedStyle: [],
    selectedSubject: [],
    medium: {
      title: "Medium",
      items: [
        "Oil",
        "Acrylic",
        "Watercolor",
        "Pastel",
        "Charcoal",
        "Mixed Media",
      ],
    },
    proportion: {
      title: "Proportion",
      items: ["Landscape", "Portrait", "Square"],
    },
    style: {
      title: "Style",
      items: ["Traditional", "Modern", "Decorative", "Abstract"],
    },
    subject: {
      title: "Subject",
    },
  }),
  mounted: async function() {},
  computed: {
    ...mapGetters("categoriesList", ["loading", "error", "field"]),

    listFilter: function() {
      return this.field;
    },
    loadingFilter: function() {
      return this.loading;
    },
  },
  created: function() {
    this.$store.dispatch("categoriesList/getField");
    if (this.paramsCategory) {
      this.selectedSubject.push(+this.paramsCategory);
    }
  },
  methods: {
    isChecked() {
      const data = {
        medium: this.selectedMedium,
        proportion: this.selectedProportion,
        style: this.selectedStyle,
        subject: this.selectedSubject,
      };

      if (this.paramsCategory) {
        this.$router.replace({ query: "" });
      }

      this.$nextTick(() => {
        this.$emit("options", data);
      });
    },
    closeSidebar() {
      this.$emit("close");
    },
    cleanSidebar() {
      if (
        this.selectedMedium.length == 0 &&
        this.selectedProportion.length == 0 &&
        this.selectedStyle.length == 0 &&
        this.selectedSubject.length == 0
      ) {
        return;
      }
      this.selectedMedium = [];
      this.selectedProportion = [];
      this.selectedStyle = [];
      this.selectedSubject = [];

      this.isChecked();
    },
  },
  props: ["items", "paramsCategory"],
  watch: {
    items: {
      immediate: true,
      handler(val) {
        this.drawer = val;
      },
    },
  },
};
</script>

<style lang="scss">
@import "./style-sidebar-art-categories.scss";
</style>
