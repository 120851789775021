<template>
  <div class="aa-my-account__orders">
    <ul class="aa-my-account__orders-title" v-if="!loading">
      <li v-for="title in this.heading" :key="title">
        {{ title }}
      </li>
    </ul>

    <ul class="aa-my-account__orders-body" v-if="!loading && !this.pageLoading">
      <li
        v-for="item in pageData"
        :key="item.invoice_id"
        class="aa-my-account__orders-item"
      >
        <div class="aa-my-account__orders-item-id">
          <span>Invoice ID</span>
          <div @click="openOrdersId(item)">
            {{ item.invoice_id ? item.invoice_id : "Empty ID" }}
          </div>
        </div>
        <div><span>Num Items</span>{{ item.order_items.length }}</div>
        <div><span>Total</span>${{ item.product_total }}</div>
        <div><span>Order Date</span>{{ dateFormatted(item.created_at) }}</div>
      </li>
    </ul>

    <OrdersId
      v-if="ordersIdFlag"
      @close-orders-id="closeOrdersId"
      :ordersIdData="ordersIdData"
    />

    <v-row
      class="fill-height pa-md-16"
      align="center"
      justify="center"
      v-if="loading || this.pageLoading"
    >
      <v-progress-circular indeterminate color="red" :size="100" :width="10"
        >Loading</v-progress-circular
      >
    </v-row>

    <div class="v-pagination__inner" v-if="!loading && this.pageLength > 1">
      <v-pagination
        v-model="page"
        :length="this.pageLength"
        @input="gatPaginationPage"
        :total-visible="6"
        color="none"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrderService from "@/services/OrderService";
import OrdersId from "@/components/Popups/OrdersId";
import moment from "moment";

export default {
  data: () => ({
    heading: ["Invoice ID", "Num Items", "Total", "Order Date"],
    page: 1,
    pages: null,
    pageData: null,
    pageDataLength: null,
    pageCurrent: null,
    pageLength: null,
    pageLoading: false,
    loading: true,
    ordersIdFlag: false,
    ordersIdData: null,
  }),
  components: { OrdersId },
  created: async function () {
    this.$store.dispatch("order/getOrders");
  },
  computed: {
    ...mapGetters("order", ["error", "orders"]),
    upOrders() {
      if (!this.orders) {
        return null;
      }
      return this.orders;
    },
  },
  methods: {
    async gatPaginationPage() {
      if (this.pageDataLength != 0) {
        this.pageLoading = true;
      }
      try {
        const response = await OrderService.getOrderPagination(this.page);
        this.pageData = response.data;
        this.pageDataLength = response.data.length;
        this.pageCurrent = response.data;

        this.pageLoading = false;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    dateFormatted(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    closeOrdersId: function () {
      this.ordersIdFlag = false;
    },
    openOrdersId: function (data) {
      this.ordersIdData = data;
      this.ordersIdFlag = true;
    },
  },
  mounted: async function () {
    this.pages = await OrderService.getOrderPagination(this.page);

    if (this.pages && this.pages.length != 0) {
      this.pageData = this.pages.data;
      this.pageDataLength = this.pages.data.length;
      this.pageCurrent = this.pages.data;
      this.pageLength = this.pages.last_page;
      this.loading = false;
    }
  },
};
</script>


<style lang="scss">
@import "./orders-style.scss";
</style>