import * as API from "@/services/API";

export default {
  getProducts() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/products`)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
