<template>
  <div class="aa-portfolio__banner">
    <button class="aa-portfolio__banner-img" @click="$refs.fileBanner.click()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.6562 4.6406L9.16656 6.875H20.8332L19.3436 4.6406C18.9727 4.0842 18.3482 3.75 17.6795 3.75H12.3203C11.6516 3.75 11.0271 4.0842 10.6562 4.6406ZM2.5 12.75C2.5 10.5409 4.29086 8.75 6.5 8.75H23.5C25.7091 8.75 27.5 10.5409 27.5 12.75V22.25C27.5 24.4591 25.7091 26.25 23.5 26.25H6.5C4.29086 26.25 2.5 24.4591 2.5 22.25V12.75ZM18.75 17.5C18.75 19.5711 17.0711 21.25 15 21.25C12.9289 21.25 11.25 19.5711 11.25 17.5C11.25 15.4289 12.9289 13.75 15 13.75C17.0711 13.75 18.75 15.4289 18.75 17.5Z"
          fill="#9D2235"
        />
      </svg>
      Edit cover photo
    </button>

    <v-menu
      offset-y
      :nudge-top="-10"
      v-if="this.authUser.profile_background || this.authUser.profile_image"
    >
      <template v-slot:activator="{ on, attrs }">
        <button class="aa-portfolio__banner-close" v-bind="attrs" v-on="on">
          &#215;
        </button>
      </template>
      <v-list shaped flat class="aa-portfolio__banner-close-list">
        <v-list-item
          @click="deleteAvatarImage"
          v-if="this.authUser.profile_image"
        >
          <v-list-item-title>Remove Profile Image</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="deleteBannerImage"
          v-if="this.authUser.profile_background"
        >
          <v-list-item-title>Remove Cover Image</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <input
      type="file"
      ref="fileBanner"
      accept="image/*"
      @change="onFileBanner"
      v-show="this.fileFlag"
    />

    <v-img
      :src="
        this.authUser.profile_background
          ? $func.src(this.authUser.profile_background)
          : '/images/banner-portfolio.webp'
      "
      aspect-ratio="1"
      max-height="370"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-linear
            bottom
            absolute
            indeterminate
            color="red"
          ></v-progress-linear>
        </v-row>
      </template>
    </v-img>
    <div class="aa-portfolio__change-img">
      <input
        type="file"
        ref="fileAvatar"
        accept="image/*"
        @change="onFileAvatar"
        v-show="this.fileFlag"
      />
      <div class="aa-portfolio__change-tip">
        <span
          @click="$refs.fileAvatar.click()"
          :class="{ active: this.authUser.profile_image }"
        >
          <svg
            v-if="!this.authUser.profile_image"
            width="38"
            height="34"
            viewBox="0 0 38 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M37.75 23.9485V16.0074C37.75 10.5251 33.0863 6.08088 27.3333 6.08088H27.0708C26.1457 2.6558 22.883 0.125 19 0.125C15.117 0.125 11.8543 2.6558 10.9292 6.08088H10.6667C4.9137 6.08088 0.25 10.5251 0.25 16.0074V23.9485C0.25 29.4308 4.9137 33.875 10.6667 33.875H27.3333C33.0863 33.875 37.75 29.4308 37.75 23.9485Z"
              fill="white"
            />
            <path
              d="M24.625 18.875C24.625 15.7684 22.1066 13.25 19 13.25C15.8934 13.25 13.375 15.7684 13.375 18.875C13.375 21.9816 15.8934 24.5 19 24.5C22.1066 24.5 24.625 21.9816 24.625 18.875Z"
              fill="white"
            />
          </svg>

          <v-img
            v-else
            :src="
              this.authUser.profile_image
                ? $func.src(this.authUser.profile_image)
                : '/images/placehold.webp'
            "
            aspect-ratio="1"
            cover
            max-height="120"
            max-width="120"
          ></v-img>
        </span>
        <p class="p__medium" @click="$refs.fileAvatar.click()">
          Change Profile Image
        </p>
      </div>
      <p class="aa-portfolio__change-info p__medium">
        Image Dimensions 1920x370
      </p>
    </div>

    <v-progress-linear
      bottom
      absolute
      indeterminate
      color="red"
      v-if="selectedLoading"
    ></v-progress-linear>
  </div>
</template>

<script>
import ArtistImages from "@/services/ArtistImages";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    fileFlag: false,
    selectedAvatar: null,
    selectedLoading: false,
    selectedBanner: null,
    selectedAvatarFlag: false,
    selectedBannerFlag: false,
    rules: [
      (value) =>
        !value ||
        value.size < 10000000 ||
        "Avatar size should be less than 2 MB!",
    ],
  }),
  computed: {
    ...mapGetters("auth", ["error", "authUser"]),
  },
  methods: {
    async deleteAvatarImage() {
      await ArtistImages.deleteArtistAvatar();
      this.$store.dispatch("auth/getAuthUser");
    },

    async deleteBannerImage() {
      await ArtistImages.deleteArtistBanner();
      this.$store.dispatch("auth/getAuthUser");
    },
    async onFileAvatar(event) {
      this.selectedLoading = true;
      this.selectedFile = await event.target.files[0];
      const fd = new FormData();
      try {
        await fd.append(
          "profile_image",
          this.selectedFile,
          this.selectedFile.name
        );

        if (event.target.files?.length) {
          await ArtistImages.addArtistAvatar(fd);
        }
        this.$store.dispatch("auth/getAuthUser");
        this.selectedLoading = false;
        this.selectedAvatarFlag = true;
      } catch (err) {
        console.log(err);
      }
    },
    async onFileBanner(event) {
      this.selectedLoading = true;
      this.selectedBanner = await event.target.files[0];

      const fd = new FormData();
      try {
        await fd.append(
          "profile_background",
          this.selectedBanner,
          this.selectedBanner.name
        );

        await ArtistImages.addArtistBanner(fd);
        this.$store.dispatch("auth/getAuthUser");
        this.selectedLoading = false;
        this.selectedBannerFlag = true;
      } catch (err) {
        console.log(err);
      }
    },
    cleanFileBanner: function() {
      console.log(1);
    },
  },
};
</script>

<style lang="scss">
@import "./portfolio-style.scss";
</style>
