<template>
  <v-card :rounded="false" flat>
    <v-layout column>
      <h3>Forgot Password</h3>
      <v-form ref="form" lazy-validation @submit.prevent="submit">
        <div>
          <span>Email</span>
          <v-text-field
            v-model="email"
            :rules="loginEmailRules"
            required
            :error="errors.hasOwnProperty('email')"
            :messages="errors.email"
            :disabled="loading"
            @click:append="show1 = !show1"
          ></v-text-field>
        </div>
        <v-layout align-center justify-center>
          <button
            :class="{ loading: loading }"
            :disabled="loading"
            type="submit"
            class="btn btn-primary arrow"
          >
            Submit
          </button>
        </v-layout>
      </v-form>
    </v-layout>
  </v-card>
</template>

<script>
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
export default {
  name: "ForgotPasswordForm",
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$emit("formLoad", this.loading);
        const payload = {
          email: this.email,
        };
        this.error = null;
        try {
          await AuthService.forgotPassword(payload);
          this.$store.dispatch("snackbar/showSnack", {
            text: "You should receive a password reset email shortly.",
            color: "success",
            timeout: 2000,
          });
          this.email = "";
        } catch (error) {
          this.errors = getError(error);
        }
        this.loading = false;
        this.$emit("formLoad", this.loading);
      }
    },
  },
  mounted: function() {
    this.$store.dispatch("loader/showLoader", {
      loading: false,
    });
  },
  data: () => {
    return {
      loading: false,
      email: null,
      errors: {},
      show1: false,
      rememberMe: false,
      requiredRules: [(v) => !!v || "This field is required"],
      loginEmailRules: [
        (v) => !!v || "Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
};
</script>

<style lang="scss">
#forgot-password {
  h3 {
    text-align: center;
    margin-bottom: 30px;
  }
}
</style>
