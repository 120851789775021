<template>
  <div class="aa-artist-details">
    <div class="aa-artist-details__background">
      <v-img
        :src="
          this.fields.profile_background
            ? $func.src(this.fields.profile_background)
            : '/images/banner-portfolio.webp'
        "
        lazy-src="/images/placehold.webp"
        aspect-ratio="1.4"
        max-height="370"
        cover
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-linear
              bottom
              absolute
              indeterminate
              color="red"
            ></v-progress-linear>
          </v-row>
        </template>
      </v-img>
    </div>
    <div class="aa-artist-details__banner container-single">
      <v-container>
        <div class="aa-artist-details__banner-wrapper">
          <h1 v-if="this.fields">{{ this.fields.artist_name }}</h1>
          <p v-if="this.fields.description">{{ this.fields.description }}</p>
        </div>
      </v-container>
    </div>

    <div class="aa-artist-details__search" v-if="this.globalData.length !== 0">
      <v-container>
        <v-layout class="aa-artist-details__search-wrap">
          <input
            v-model="search"
            placeholder="Search"
            type="text"
            @keydown.enter="$refs.searchBtn.click()"
          />
          <button
            class="btn btn-primary arrow"
            ref="searchBtn"
            @click="isPagination"
          >
            Search
          </button>
        </v-layout>
      </v-container>
    </div>

    <div
      class="aa-artist-details__alert"
      v-if="this.pageData.length == 0 && this.globalData.length"
    >
      <v-container> <h2>No results</h2> </v-container>
    </div>

    <div
      class="aa-artist-details__masonry"
      v-if="this.pageData.length !== 0 && this.pageRefresh"
    >
      <v-container>
        <div class="aa-artist-details__masonry-wrap">
          <vue-masonry-wall
            :ssr="{ columns: 0 }"
            :items="this.pageData"
            :options="{ width: 300, padding: 0 }"
          >
            <template v-slot:default="{ item }">
              <div class="aa-artist-details__masonry-item">
                <router-link :to="`/order?image_id=${item.id}&mode=create`">
                  <img
                    :src="
                      item.preview_image.web_path
                        ? item.preview_image.web_path
                        : '/images/placehold.webp'
                    "
                    :alt="item && item.name"
                    :title="item && item.name"
                  />

                  <div class="aa-artist-details__masonry-buttons">
                    <i class="aa-icon aa-icon-arrow"></i>
                  </div>
                </router-link>

                <h4>
                  <router-link :to="`/order?image_id=${item.id}&mode=create`">
                    {{ item.name ? item.name : "Untitled" }}
                  </router-link>
                </h4>
              </div>
            </template>
          </vue-masonry-wall>
        </div>

        <div
          class="v-pagination__inner"
          v-if="this.pageData.length !== 0 && this.pageLength > 1"
        >
          <v-pagination
            v-model="pageCurrent"
            :length="this.pageLength"
            @input="isPagination"
            :total-visible="6"
            color="none"
          ></v-pagination>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import VueMasonryWall from "vue-masonry-wall";
import ArtistDetails from "@/services/ArtistDetails";

export default {
  data: () => ({
    search: "",
    globalData: [],
    fields: [],
    pageData: [],
    pageCurrent: 1,
    pageLength: null,
    pageRefresh: true,
  }),
  components: { VueMasonryWall },
  computed: {
    currentPage() {
      return this.products
        .slice()
        .find((product) => product.slug === this.$route.params.artist);
    },
  },
  methods: {
    async isPagination(page) {
      let valueSearch = this.search;
      if (typeof page !== "number") {
        this.pageCurrent = 1;
        if (valueSearch === "") {
          valueSearch = "";
        } else {
          valueSearch = "&search=" + valueSearch;
        }
      }

      if (this.pageCurrentFlag != page) {
        this.pageLoading = true;
        try {
          const data = await ArtistDetails.getArtistImages(
            this.pageCurrent,
            this.$route.params.artist,
            valueSearch
          );

          if (data) {
            this.pageData = data.data;
            this.pageLength = data.last_page;
            this.pageCurrentFlag = data.current_page;
          }
        } catch (err) {
          console.log(err);
        }

        // loading overlay pagination
        this.pageRefresh = false;
        await this.$nextTick();
        this.pageRefresh = true;

        this.pageLoading = false;
      }
    },
  },
  mounted: async function() {
    try {
      const fields = await ArtistDetails.getArtistInfo(
        this.$route.params.artist
      );

      if (fields) {
        this.fields = fields;
      }
    } catch (err) {
      this.$router.push("/artist-archive");
      console.log(err);
    }

    try {
      const data = await ArtistDetails.getArtistImages(
        this.pageCurrent,
        this.$route.params.artist,
        this.search
      );

      if (data) {
        this.pageData = data.data;
        this.globalData = data.data;
        this.pageLength = data.last_page;
        this.pageCurrentFlag = data.current_page;
      }

      this.$store.dispatch("loader/showLoader", {
        loading: false,
      });
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style lang="scss">
@import "./style-artist-details.scss";
</style>
