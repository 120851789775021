<template>
  <div class="aa-portfolio">
    <image-edit
      v-bind="selectedImage"
      v-if="editingImage"
      @close-edit-dialog="closeEditDialog"
      @updated-image="handleImageUpdate"
    />
    <download-dialog
      :selectedImage="selectedImage"
      v-if="downloadingImage"
      @close-download-dialog="closeDownloadDialog"
    />
    <confirmation-dialog
      v-if="deletingImage"
      @close-dialog="closeDeleteDialog"
      @confirm-dialog="confirmDeleteImage"
      acceptColor="error"
      acceptLabel="Delete"
      :loading="deleteLoading"
      loadingText="Deleting Image..."
      ><p>
        Are you sure you want to delete this image? This Image will still be on
        file and can be restored if you contact us.
      </p></confirmation-dialog
    >

    <div class="aa-portfolio__gallery">
      <v-layout class="aa-portfolio__search" align-center justify-space-between>
        <label>
          <input
            :disabled="loading || this.pageLoading || !this.filteredImages"
            v-model="search"
            placeholder="Search"
            type="text"
            @input="isSearchChange"
            @keyup.enter="$refs.isSearchBtn.click()"
          />
          <i
            class="aa-portfolio__search-clean"
            v-show="search !== '' && search.length != 0"
            @click="isSearchClean"
            :disabled="pageLoading"
          ></i>
        </label>
        <button
          @click="isSearch"
          :disabled="loading || this.pageLoading || !this.filteredImages"
          class="btn btn-primary arrow"
          ref="isSearchBtn"
        >
          Search
        </button>
      </v-layout>

      <v-row
        class="fill-height ma-16"
        align="center"
        justify="center"
        v-if="loading || pageLoading"
      >
        <v-progress-circular indeterminate color="red" :size="100" :width="10">
          Loading
        </v-progress-circular>
      </v-row>

      <v-alert
        v-if="
          !this.pageLoading &&
            this.imagesData.length == 0 &&
            this.filteredImages
        "
        class="mt-10"
        color="red"
        text
        align="center"
      >
        <p>No results</p>
        <span v-if="this.searchChange"
          >Make sure that all words were spelled correctly, or search for a new
          keyword.</span
        >
        <span v-else> No matches were found for "{{ this.searchAlert }}".</span>
      </v-alert>

      <v-row
        v-if="!this.filteredImages && !this.searchAlert && !this.pageLoading"
      >
        <v-col md="6">
          <v-alert border="left" colored-border elevation="2">
            You have no images yet. Please contact Archival Arts to have images
            added to your gallery.
          </v-alert>
        </v-col>
      </v-row>

      <div
        class="aa-portfolio__gallery-list"
        v-if="!this.pageLoading && this.imagesData"
        :class="{ 'aa-list-style': this.listStyle }"
      >
        <v-card
          v-for="(image, index) in this.imagesData
            ? this.imagesData
            : this.filteredImages"
          :key="image.id"
        >
          <SellInMarket
            :items="{ id: image.id, sell: image.sale_in_market }"
            @updated-image="handleImageUpdate"
          />

          <div class="aa-portfolio__gallery-list-title">
            <v-img
              cover
              :src="
                image ? image.preview_image.web_path : '/images/placehold.webp'
              "
              lazy-src="/images/placehold.webp"
              height="300px"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="red"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <h3 :class="{ 'font-italic': !image.name }">
              {{ image.name ? image.name : image.computed_name }}
            </h3>

            <!-- <v-tooltip bottom :class="{ active: filterAuth.markup }">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{
                    filterAuth.markup ? filterAuth.markup + "%" : "--"
                  }}</span>
                </template>
                <span>Markup</span>
              </v-tooltip> -->
          </div>

          <div v-show="listStyle" class="aa-portfolio__actions-id">
            ID: <span>{{ image.id }}</span>
          </div>

          <div class="aa-portfolio__actions">
            <div v-show="!listStyle" class="aa-portfolio__actions-id">
              ID: <span>{{ image.id }}</span>
            </div>
            <v-card-actions>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :id="index === 0 ? 'tour-orderButton' : null"
                    :to="`/order?image_id=${image.id}&mode=create`"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="aa-icon aa-icon-cart"></i>
                  </v-btn>
                </template>
                <span>Order Prints</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :id="index === 0 ? 'tour-downloadButton' : null"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="openDownloadDialog(image)"
                  >
                    <i class="aa-icon aa-icon-download"></i>
                  </v-btn>
                </template>
                <span>Download</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :id="index === 0 ? 'tour-editButton' : null"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="startEditingImage(image)"
                  >
                    <i class="aa-icon-edit-rectangle aa-icon"></i>
                  </v-btn>
                </template>
                <span>Edit Details</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :id="index === 0 ? 'tour-deleteButton' : null"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="openDeleteDialog(image)"
                  >
                    <i class="aa-icon aa-icon-trash"></i>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-card-actions>
          </div>
        </v-card>
      </div>
    </div>

    <div class="v-pagination__inner" v-if="this.pageLength > 1">
      <v-pagination
        :disabled="this.pageLoading"
        v-model="pageCurrent"
        :length="this.pageLength"
        @input="getImagesData"
        :total-visible="9"
        color="none"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import DownloadDialog from "@/components/Popups/DownloadDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import ImageEdit from "@/components/Popups/ImageEdit";
import SellInMarket from "@/components/Checkbox/SellInMarket";
import ImageService from "@/services/ImageService";

import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    DownloadDialog,
    ImageEdit,
    ConfirmationDialog,
    SellInMarket,
  },
  props: {
    viewListCurrent: {
      type: Boolean,
      default: false,
    },
    viewSortCurrent: {
      type: String,
    },
  },
  created: async function() {
    this.$store.dispatch("image/getImages");
  },
  computed: {
    ...mapGetters("image", ["loading", "error", "images"]),

    filteredImages: function() {
      return this.images.data;
    },
    filteredError: function() {
      return this.error;
    },
  },
  watch: {
    viewListCurrent: function(newValue) {
      this.listStyle = newValue;
    },
    viewSortCurrent: function(newValue) {
      if (newValue === "A-Z") {
        this.orderBy = "asc";
      } else {
        this.orderBy = "desc";
      }
      this.getImagesData();
    },
  },
  mounted: async function() {
    this.getImagesData();
  },
  methods: {
    isSearch() {
      this.pageCurrent = 1;
      this.pageBefore = 1;
      this.getImagesData();
    },
    isSearchChange() {
      if (this.imagesData.length == 0 && !this.imagesData) {
        return (this.searchChange = true);
      }
    },
    isSearchClean() {
      this.search = "";
    },
    getImagesData: async function(page) {
      if (page) {
        if (page == this.pageBefore) {
          return;
        }
        this.pageBefore = page;
      }

      this.searchAlert = this.search;
      this.pageLoading = true;

      try {
        const response = await ImageService.getImageSearch(
          this.pageCurrent,
          this.search,
          this.orderBy
        );

        this.imagesData = response.data;
        this.pageLength = response.last_page;
        this.pageLoading = false;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    startEditingImage: function(image) {
      this.selectedImage = image;
      this.editingImage = true;
    },
    closeEditDialog: function() {
      this.selectedImage = null;
      this.editingImage = false;
    },
    handleImageUpdate: function() {
      this.selectedImage = null;
      this.editingImage = false;
      // this.$store.dispatch("image/getImages");
      // this.pageCurrent = 1;
      // this.search = "";
      this.getImagesData();
    },
    dateFormatted(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    openDownloadDialog: function(image) {
      this.selectedImage = image;
      this.downloadingImage = true;
    },
    closeDownloadDialog: function() {
      this.selectedImage = null;
      this.downloadingImage = false;
    },
    openDeleteDialog: function(image) {
      this.selectedImage = image;
      this.deletingImage = true;
    },
    closeDeleteDialog: function() {
      this.selectedImage = null;
      this.deletingImage = false;
    },
    confirmDeleteImage: async function() {
      try {
        this.deleteLoading = true;
        await ImageService.deleteImage(this.selectedImage.id);
        this.deleteLoading = false;
        this.closeDeleteDialog();
        this.$store.dispatch("image/getImages");
      } catch (error) {
        console.error(error);
        this.deleteLoading = false;
      }
    },
  },
  data: () => ({
    tab: "grid",
    listStyle: false,
    selectedImage: null,
    editingImage: false,
    downloadingImage: false,
    deletingImage: false,
    deleteLoading: false,
    search: "",
    searchAlert: "",
    orderBy: "asc",
    searchChange: false,
    searchBtn: false,
    pageCurrent: 1,
    pageBefore: 1,
    pageLoading: false,
    pageLength: null,
    imagesData: [],
    headers: [
      {
        text: "Image",
        align: "start",
        sortable: false,
        value: "image",
      },
      { text: "Name", value: "name" },
      { text: "Original Width", value: "original_width" },
      { text: "Original Height", value: "original_height" },
      { text: "Date Scanned", value: "date_scanned" },
      { text: "Updated Date", value: "updated_at" },
      { text: "Actions", value: "actions", sortable: false, align: "end" },
    ],
    tour: [
      //   {
      //     target: '#tour-menuButton',
      //     header: { title: 'Get Started' },
      //     content: 'Click on this button to open the main menu.',
      //     params: {
      //       highlight: false,
      //       enableScrolling: false,
      //     },
      //   },
      {
        target: "#tour-orderButton",
        header: { title: "Get Started" },
        content: "Click on this button to order prints of this piece.",
        params: {
          highlight: false,
          enableScrolling: false,
        },
      },
      {
        target: "#tour-editButton",
        header: { title: "Get Started" },
        content: "Click on this button to edit the details of an image.",
        params: {
          highlight: false,
          enableScrolling: false,
        },
      },
      {
        target: "#tour-downloadButton",
        header: { title: "Get Started" },
        content: "Click on this button to download different size prints.",
        params: {
          highlight: false,
          enableScrolling: false,
        },
      },
    ],
  }),
};
</script>

<style lang="scss">
@import "./my-portfolio.scss";
</style>
