<template>
  <div class="aa-checkout__main-item">
    <v-img
      v-if="item.image"
      :src="
        item.image.preview_image
          ? item.image.preview_image.path
          : '/images/placehold.webp'
      "
      width="200px"
      height="180px"
      lazy-src="/images/placehold.webp"
      aspect-ratio="1.4"
      cover
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="red"></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <div class="aa-checkout__main-item-info">
      <div class="aa-checkout__main-item-params">
        <h3>{{ title }}</h3>
        <p v-if="item.image">{{ media }}</p>
        <p v-if="item.image">{{ size }}</p>
        <p v-if="item.image">{{ matted }}</p>
        <p v-if="item.image">{{ framed }}</p>
        <p v-if="!item.image && item.description">
          {{ item.description }}
        </p>
        <p>{{ item.quantity }} item<span v-if="item.quantity > 1">s </span></p>
      </div>

      <div class="aa-checkout__main-item-actions">
        <div class="aa-checkout__main-item-price">
          <h4 v-if="item.discount_total && item.discount_total > 0">
            Product Total:
            <span>{{ item.product_total | currency }}</span>
          </h4>

          <h4 v-if="item.discount_total && item.discount_total > 0">
            Discount:
            <span>-{{ item.discount_total | currency }}</span>
          </h4>

          <h3>
            {{ item.total | currency }}
          </h3>
        </div>

        <div class="aa-checkout__main-item-btn">
          <v-btn v-if="item.image" icon @click="editCartItem(item)">
            <i class="aa-icon-edit-rectangle aa-icon"></i>
          </v-btn>
          <v-btn :loading="loading[item.id]" icon @click="deleteCartItem(item)">
            <i class="aa-icon aa-icon-trash"></i>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartService from "@/services/CartService";
import ConfigService from "@/services/ConfigService";
import { mapGetters } from "vuex";

export default {
  name: "CartItemCard",
  props: ["item"],
  mounted: async function() {
    this.medias = await ConfigService.getMedias();
    this.frames = await ConfigService.getFrames();
  },
  methods: {
    editCartItem: function(item) {
      if (this.authUser) {
        this.$router.push({
          path: `/order?cart_item_id=${item.id}&mode=edit`,
        });
      } else {
        this.$router.push({
          name: "Order",
          query: {
            cart_item_id: item.local_id,
            mode: "edit",
          },
        });
      }
    },
    deleteCartItem: async function(item) {
      if (this.authUser) {
        this.$set(this.loading, this.item.id, true);
        await CartService.deleteCartItem(this.item.id);
        this.$store.dispatch("cart/getCart");
        this.$delete(this.loading, this.item.id);

        this.$emit("events", false);
      } else {
        let localData = JSON.parse(localStorage.getItem("guestCart") || "[]");
        let localItems = localData.cart_items;
        let dataLength = 0,
          dataProductTotal = 0,
          dataGrandTotal = 0,
          dataDiscountTotal = 0;

        if (localData.length == 0) {
          this.$router.go(0);
          return false;
        }

        localItems = localItems.filter(function(obj) {
          return obj.local_id !== item.local_id;
        });

        if (localData.length !== 0) {
          localItems.forEach(function(e) {
            dataLength += e.quantity;
            dataProductTotal += e.product_total;
            dataGrandTotal += e.total;
            dataDiscountTotal += e.discount_total;
          });
        }

        localData.cart_items = localItems;
        localData.total_items = dataLength;
        localData.product_total = dataProductTotal;
        localData.grand_total = dataGrandTotal;
        localData.discount_total = dataDiscountTotal;

        localStorage.setItem("guestCart", JSON.stringify(localData));

        if (localData.cart_items.length == 0) {
          localStorage.removeItem("guestCart");
        }

        this.$eventHub.$emit("upDataCart", true);
        this.$emit("events", false);
      }
    },
  },
  data: function() {
    return {
      cartOpen: false,
      loading: {},
      quantity: 1,
      medias: [],
      frames: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["error", "authUser"]),
    media: function() {
      const mediaValue = this.item.print_options.media;
      if (mediaValue) {
        const selectedMedia = this.medias.find((media) => {
          return media.value === mediaValue;
        });

        if (selectedMedia) {
          return selectedMedia.text;
        }
      }

      return "";
    },
    matted: function() {
      if (this.item.print_options.mat) {
        return "Matted";
      }

      return "";
    },
    title: function() {
      if (this.item.image) {
        return this.item.image.name ? this.item.image.name : "Untitled";
      }

      if (this.item.product) {
        return this.item.product.name;
      }
      return null;
    },
    framed: function() {
      const frameValue = this.item.print_options.frame;
      if (frameValue) {
        const selectedFrame = this.frames.find((frame) => {
          return frame.value === frameValue;
        });

        if (selectedFrame) {
          return selectedFrame.text;
        }
      }

      return "";
    },
    size: function() {
      if (
        this.item.print_options.printHeight &&
        this.item.print_options.printWidth
      ) {
        return `${this.item.print_options.printWidth}x${this.item.print_options.printHeight}in`;
      }
      return "";
    },
  },
  filters: {
    currency: function(value) {
      if (!value) return "-";
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
  },
};
</script>

<style lang="scss">
@import "./style-cart-item-card.scss";
</style>
