<template>
  <v-app :dark="false" :light="false" id="inspire">
    <Snackbar />

    <div class="aa-main">
      <Header />

      <!-- <MainMenu /> -->

      <slot></slot>
    </div>

    <aa-footer />
  </v-app>
</template>

<script>
import Footer from "../components/Footer/Footer.vue";
// import MainMenu from '../components/MainMenu.vue';
import Header from "../components/Header/Header.vue";
import Snackbar from "../components/Snackbar.vue";
// import { mapGetters } from "vuex";

export default {
  components: { "aa-footer": Footer, Header, Snackbar }
};
</script>

<style lang="scss">
.aa-main {
  flex: 1;
}
</style>
