<template>
  <section class="aa-do-card container-out">
    <v-container>
      <div class="aa-do-card__wrapper">
        <div class="aa-do-card__image">
          <div class="aa-do-card__image-wrapper">
            <v-img
              :src="
                filteredField.second_section_image
                  ? $func.src(filteredField.second_section_image)
                  : '/images/placehold.webp'
              "
              lazy-src="/images/placehold.webp"
              max-height="400"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="red"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
        </div>
        <div class="aa-do-card__content">
          <h2 class="aa-do-card__title">
            {{ filteredField.second_section_title }}
          </h2>
          <p class="aa-do-card__des">
            {{ filteredField.second_section_description }}
          </p>
          <router-link to="/categories" class="btn btn-primary__circle">
            {{ filteredField.second_section_button_text }}
          </router-link>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => {
    return { el: "" };
  },

  computed: {
    ...mapGetters("homepage", ["error", "loading", "field"]),
    filteredField: function() {
      if (!this.field || this.field === "") {
        return this.field;
      }
      return this.field;
    },
    filteredLoading: function() {
      if (!this.loading || this.loading === "") {
        return this.loading;
      }
      return this.loading;
    },
  },
};
</script>

<style lang="scss">
@import "./do-card-style";
</style>
