<template>
  <div class="aa-thank-you">
    <v-container>
      <div class="aa-thank-you__wrap">
        <svg
          width="91"
          height="84"
          viewBox="0 0 91 84"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.1797 23.6667H9.90624C4.78467 23.6667 0.632812 27.7707 0.632812 32.8334V74.0834C0.632812 79.146 4.78467 83.25 9.90624 83.25H19.1797C24.3012 83.25 28.4531 79.146 28.4531 74.0834V32.8334C28.4531 27.7707 24.3012 23.6667 19.1797 23.6667Z"
            fill="#9D2235"
          />
          <path
            opacity="0.4"
            d="M64.976 83.25H47.9788C44.3172 83.25 40.7375 82.1786 37.6909 80.1709L30.5179 75.444C29.2279 74.5939 28.4531 73.1629 28.4531 71.6304V34.0505C28.4531 33.2529 28.6637 32.4691 29.064 31.7765L46.7112 1.24952C46.8899 0.940391 47.2199 0.75 47.577 0.75H53.1608C62.4191 0.75 67.9414 10.9496 62.8058 18.5643L56.2734 28.25H81.4899C87.523 28.25 91.9497 33.8544 90.4865 39.6399L82.9691 69.3632C80.905 77.5246 73.4866 83.25 64.976 83.25Z"
            fill="#9D2235"
          />
        </svg>

        <h1>
          Thank You For Your Order
          <span v-if="$route.params.order">
            #{{ $route.params.order.invoice_id }}
          </span>
          <span v-else>
            #----
          </span>
        </h1>

        <p>
          Thank you for placing your order with Archival Arts. Your invoice id
          is
          <span v-if="$route.params.order">
            #{{ $route.params.order.invoice_id }}
          </span>
          <span v-else> #---- </span>. You should receive a confirmation email
          shortly.
        </p>
        <p>
          We will reach out to you soon with a final invoice before you are
          charged for your order. If you have any questions, call Archival Arts
          customer service at <a href="tel:4102477771">410-247-7771</a>.
        </p>

        <a class="btn btn-primary arrow" href="/">Okay</a>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ThankYou",
  created: function() {
    if (!this.$route.params.order) {
      this.$router.push("/art-categories");
    }
  },
  props: {
    order: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
@import "./style-thank-you.scss";
</style>
