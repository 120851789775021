import * as API from "@/services/API";

export default {
  getCart() {
    return API.apiClient.get("/cart");
  },
  getCartItem(id) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/cart/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addCartItem(payload) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .post(`/cart`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  clearCart(payload) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .post(`/cart/clear`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateCartItem(id, payload) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .put(`/cart/${id}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCartItem(id) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .delete(`/cart/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
