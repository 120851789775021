<template>
  <div class="aa-login-form container-out">
    <v-container>
      <div class="aa-login-form__wrapper">
        <div class="aa-login-form__overlay"></div>
        <div class="aa-login-form__tabs">
          <template>
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              color="red"
            ></v-progress-linear>
          </template>

          <v-tabs v-model="tab">
            <v-tab
              @click="isLogin"
              href="#login"
              :ripple="false"
              :disabled="this.loading"
            >
              Log In
            </v-tab>

            <v-tab
              @click="isSignUp"
              href="#signup"
              :ripple="false"
              :disabled="this.loading"
            >
              Register
            </v-tab>

            <v-tab
              href="#forgot-password"
              style="display: none"
              :disabled="this.loading"
            ></v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="login">
              <LoginForm @formLoad="activeLoad" />
            </v-tab-item>

            <v-tab-item value="signup">
              <registration-form />
            </v-tab-item>

            <v-tab-item value="forgot-password">
              <forgot-password-form @formLoad="activeLoad" />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from "@/components/Form/LoginForm.vue";
import RegistrationForm from "@/components/Form/RegistrationForm.vue";
import ForgotPasswordForm from "@/components/Form/ForgotPasswordForm.vue";

export default {
  name: "Home",
  components: {
    LoginForm,
    RegistrationForm,
    ForgotPasswordForm,
  },
  data: () => {
    return {
      tab: null,
      loading: false,
    };
  },
  watch: {
    $route() {
      this.$store.dispatch("loader/showLoader", {
        loading: false,
      });
    },
  },
  mounted() {
    const queryParam = this.$route.query.p;
    if (queryParam === "register") {
      this.tab = "signup";
    }

    if (queryParam === "forgot_password") {
      this.tab = "forgot-password";
    }
  },
  methods: {
    isSignUp() {
      const currentQuery = this.$route.query;
      if (currentQuery.p !== "register") {
        this.$router.push({ query: { p: "register" } });
      }
    },
    isLogin() {
      this.$router.replace({ query: {} });
    },
    activeLoad(el) {
      this.loading = el;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/mixin";
.aa-login-form {
  &__overlay {
    position: absolute;
    width: 450px;
    height: 1000px;
    right: -300px;
    top: 0;
    display: block;
    background: linear-gradient(
      264.74deg,
      #ffc700 8.33%,
      #ff5b5b 61.23%,
      #5a88ff 110.07%
    );
    opacity: 0.6;
    filter: blur(100px);
    transform: matrix(0.75, -0.65, 0.65, 0.75, 0, 0);
  }
  &__wrapper {
    position: relative;
  }
  &__tabs {
    max-width: 570px;
    // min-height: 558px;
    padding: 50px 85px;
    background: #ffffff;
    box-shadow: 0px 10px 48px -15px rgba(16, 24, 40, 0.15);
    border-radius: 10px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    @include md-size-max {
      padding: 50px 30px;
    }

    @include sm-size-max {
      padding: 30px 15px;
    }
  }

  &__reset {
    text-align: center;
    margin: 30px 0 0;
    span {
      cursor: pointer;
      text-decoration: underline;
      color: var(--red);
      &:hover {
        color: var(--burgundy);
      }
    }
  }

  .theme--light.v-tabs {
    margin-bottom: 50px;
    .v-tabs-bar {
      height: auto;
    }
    .v-slide-group {
      &__content {
        justify-content: center;
        gap: 50px;
        .v-tabs-slider-wrapper {
          display: none;
        }

        .v-tab {
          color: var(--silver) !important;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          border-radius: 10px;
          overflow: hidden;
          padding: 0;
          text-transform: capitalize;
          transition: var(--trans);
          letter-spacing: initial;

          &::before {
            content: none;
          }

          &:hover {
            color: var(--burgundy);
          }
          &--active {
            color: var(--red) !important;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.v-window {
  &-item {
    opacity: 0;
    visibility: hidden;

    &--active {
      visibility: visible;
      opacity: 1;
    }
  }
  .v-input {
    padding: 10px 0 0;
    margin: 0;
  }
  .v-form {
    & > div {
      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &:last-child {
        text-align: center;
        margin-top: 50px;
      }
    }
  }
}
</style>
