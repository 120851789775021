export const namespaced = true;

export const state = {
  loading: true,
};

export const mutations = {
  SHOW_MESSAGE(state, payload) {
    state.loading = payload.loading;
  },
};

export const actions = {
  showLoader({ commit }, payload) {
    commit("SHOW_MESSAGE", payload);
  },
};
