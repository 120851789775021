<template>
  <main
    class="aa-my-account container-single"
    :class="[
      active_tab === 0 ? 'my-portfolio' : '',
      active_tab === 1 ? 'sales-container' : '',
      active_tab === 2 ? 'order-container' : '',
      {
        'customer-account': filterRules == 'customer',
      },
    ]"
  >
    <div class="aa-my-account__banner" v-show="active_tab === 0">
      <PortfolioBanner />
    </div>

    <div class="aa-my-account__heading">
      <v-container v-if="filterRules != 'customer'">
        <h1 v-show="active_tab === 0">My Portfolio</h1>
        <h1 v-show="active_tab === 1 || active_tab === 2">My Account</h1>
        <h1 v-show="active_tab === 3">Artist Account</h1>
      </v-container>
      <v-container v-else>
        <h1>Customer Account</h1>
      </v-container>
    </div>

    <div class="aa-my-account__tab">
      <v-container>
        <div class="aa-my-account__tab-title">
          <div class="aa-my-account__tab-title-wrap">
            <button
              v-for="(tab, index) in tabs"
              :key="index"
              :class="[active_tab === index ? 'is-active' : '']"
              class="btn btn-third"
              :aria-selected="active_tab === index"
              @click="changeTab(index)"
            >
              {{ tab.tab_title }}
            </button>
          </div>
          <!-- <div
            class="aa-my-account__tab-title-outside-sales"
            v-show="active_tab === 1 && this.amountLength > 1"
          >
            <label for="outside-sales">
              Outside sales
              <input
                name="outside-sales"
                type="number"
                v-model="amount"
                @input="updateValue"
                @blur="handleBlur"
                :disabled="this.amountLoading"
              />
            </label>
          </div> -->

          <div class="aa-portfolio__tab-title" v-show="active_tab === 0">
            <div class="aa-portfolio__tab-sort">
              <span>Sort By</span>
              <v-select
                :items="sortItems"
                v-model="currSort"
                solo
                flat
                hide-details="auto"
              >
              </v-select>
            </div>
            <div class="aa-portfolio__tab-view">
              <span>View</span>
              <div class="aa-portfolio__tab-view-buttons">
                <v-btn
                  icon
                  :ripple="false"
                  :class="{ active: isActiveView }"
                  @click="isActiveView = true"
                >
                  <i class="aa-icon aa-icon-grid"></i>
                </v-btn>
                <v-btn
                  icon
                  :ripple="false"
                  :class="{ active: !isActiveView }"
                  @click="isActiveView = false"
                >
                  <i class="aa-icon aa-icon-list"></i>
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <div class="aa-my-account__tab-content">
          <MyPortfolio
            :aria-current="active_tab === 0"
            v-show="active_tab === 0"
            v-if="filterRules != 'customer'"
            :view-list-current="!isActiveView"
            :view-sort-current="currSort"
          />
          <Sales
            :aria-current="active_tab === 1"
            v-show="active_tab === 1"
            v-if="filterRules != 'customer'"
          />
          <Orders :aria-current="active_tab === 2" v-show="active_tab === 2" />
          <Information
            :aria-current="active_tab === 3"
            v-show="active_tab === 3"
          />
        </div>
      </v-container>
    </div>
  </main>
</template>

<script>
import Information from "@/components/ArtistAccount/Information.vue";
import Orders from "@/components/ArtistAccount/Orders.vue";
import Sales from "@/components/ArtistAccount/Sales.vue";
import MyPortfolio from "@/components/ArtistAccount/MyPortfolio.vue";

import PortfolioBanner from "@/components/Banners/PortfolioBanner.vue";
import { mapGetters } from "vuex";

export default {
  data: () => {
    return {
      active_tab: 3,
      pageCurrent: 1,
      sortItems: ["A-Z", "Z-A"],
      currSort: "A-Z",
      isActiveView: true,
      tabs: [
        {
          tab_title: "My Works",
        },
        {
          tab_title: "Sales",
        },
        {
          tab_title: "Orders",
        },
        {
          tab_title: "My Information",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["loading", "error", "authUser"]),
    filterRules: function() {
      let isUser;
      if (!this.authUser.admin && !this.authUser.artist) {
        isUser = "customer";
      }
      if (this.authUser.admin && !this.authUser.artist) {
        isUser = "admin";
      }
      if (!this.authUser.admin && this.authUser.artist) {
        isUser = "artist";
      }

      return isUser;
    },
  },
  components: { Information, Sales, Orders, PortfolioBanner, MyPortfolio },
  methods: {
    changeTab(tabIndexValue) {
      this.active_tab = tabIndexValue;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/mixin";
.aa-my-account {
  @include md-size-max {
    &.sales-container,
    &.order-container {
      .aa-my-account__tab {
        & > .container {
          padding: 0;
        }
      }
    }
  }

  &.customer-account {
    &.order-container {
      .aa-my-account__tab-title {
        @include md-size-max {
          padding: 0 20px;
        }
      }
    }
    .aa-my-account__tab-title {
      @include md-size-max {
        justify-content: flex-start;
      }
    }
    .aa-my-account__tab-title-wrap {
      gap: 50px;

      @include md-size-max {
        display: flex;
      }
      button {
        padding: 0 !important;
        background-color: transparent;
        color: var(--silver);
        font-weight: 600;
        border: none;
        font-size: 24px;
        height: auto;
        line-height: 150%;

        @include md-size-max {
          font-size: 18px;
        }

        &.is-active {
          color: var(--red);
          position: relative;
          &::after {
            content: "";
            background-color: currentColor;
            height: 2px;
            inset: auto 0 4px 0;
            position: absolute;
          }
        }
        &:nth-child(-n + 2) {
          display: none;
        }
      }
    }
  }

  &.sales-container {
    .aa-my-account__tab-title {
      @include xlg-size-max {
        gap: 30px;
        flex-direction: column;
      }
      @include md-size-max {
        align-items: center;
        gap: 15px;
      }
    }
  }
  &__heading {
    h1 {
      text-align: center;
      color: var(--red);
      margin: 0;
    }
    margin-bottom: 50px;
    @include xl-size-max {
      margin-bottom: 40px;
    }
  }
  &__tab {
    &-title {
      display: flex;
      justify-content: space-between;
      @include md-size-max {
        justify-content: center;
      }
      &-wrap {
        display: flex;
        gap: 30px;
        @include lg-size-max {
          gap: 15px;
        }
        @include md-size-max {
          display: inline-grid;
          grid-template-columns: 1fr 1fr;
          justify-content: center;
          & > button {
            justify-content: center;
          }
        }

        @include sm-size-max {
          grid-template-columns: 1fr;
        }
      }
      &-outside-sales {
        display: flex;
        align-items: center;

        input {
          width: 70px;
          margin: 0;
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            appearance: none;
          }
        }
      }

      margin-bottom: 50px;
      @include xl-size-max {
        margin-bottom: 40px;
      }

      button.is-active {
        background-color: var(--red);
        color: var(--light);
      }
    }
  }

  &.my-portfolio {
    .aa-my-account__tab-title {
      gap: 30px;
      @include xl-size-max {
        flex-direction: column;
      }
    }
  }
}

.aa-portfolio__tab {
  &-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;

    & > * {
      display: flex;
      align-items: center;
      gap: 20px;

      & > span {
        color: var(--red);
      }
    }

    i.aa-icon {
      height: 28px;
      width: 28px;
    }

    .v-btn--icon {
      height: 30px;
      width: 30px;
    }
    .v-list-item__content {
      padding: 17px 12px;
    }
  }

  &-view {
    &-buttons {
      display: flex;
      gap: 15px;
      align-items: center;

      .active {
        i:after {
          filter: brightness(0);
        }
      }
    }
  }

  &-sort {
    .v-input {
      height: 53px;
    }
    .v-input__slot {
      border-color: var(--red) !important;
    }
    .v-input__icon {
      width: 38px !important;
    }

    .v-select__selections {
      color: var(--red) !important;
      input {
        padding: 0 2px !important;
      }
    }
  }
}
</style>
