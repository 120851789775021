import * as API from "@/services/API";

export default {
  getCategoriesList() {
    return API.apiClient.get("/catalog-get-filter-categories");
  },
  getImages(page, search, options) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/catalog-get-images?page=${page}${search}${options}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
