<template>
  <v-navigation-drawer fixed app>
    <v-list-item>
      <v-list-item-icon>
        <v-avatar class="white--text" color="primary" size="56">DA</v-avatar>
      </v-list-item-icon>
      <v-list-item-content>
        David Allen
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list nav>
      <v-list-item to="/gallery">
        <v-list-item-icon>
          <v-icon>mdi-palette</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Manage Gallery</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/order">
        <v-list-item-icon>
          <v-icon>mdi-cart-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Order</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/orders">
        <v-list-item-icon>
          <v-icon>mdi-file-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>View Orders</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn class="secondary" block>
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MainMenu",
  props: {
    drawer: Boolean
  },
  methods: {
    handleClickOutside() {
      if (this.menuOpen) {
        this.$state.dispatch("menu/toggle");
      }
    }
  },
  computed: {
    ...mapState({
      menuOpen: state => state.menu.open
    })
  }
};
</script>
