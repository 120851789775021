import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
// import Gallery from "../views/Gallery.vue";
import Checkout from "@/views/Checkout/Checkout.vue";
import ThankYou from "../views/ThankYou/ThankYou.vue";
import MyAccount from "../views/MyAccount.vue";
import Order from "../views/Order/Order.vue";
// import MyOrders from "../views/MyOrders.vue";
import ResetPassword from "../views/ResetPassword.vue";
import store from "@/store/index";
import auth from "@/middleware/auth";
// import admin from '@/middleware/admin';
import guest from "@/middleware/guest";
import middlewarePipeline from "@/router/middlewarePipeline";
import PageNotFound from "../views/404/PageNotFound";

import Categories from "../views/CategoriesPage/CategoriesPage.vue";
import Contact from "@/views/ContactUs/Contact.vue";
import ArtistArchive from "@/views/ArtistArchive/ArtistArchive.vue";
import ArtCategories from "@/views/ArtCategories/ArtCategories.vue";
import ArtistDetails from "@/views/ArtistDetails/ArtistDetails.vue";
import CartPage from "@/views/CartPage/CartPage.vue";
import ReturnPolicy from "@/views/ReturnPolicy/ReturnPolicy.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy/PrivacyPolicy.vue";

import Home from "../views/Home.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { middleware: [guest] },
  },
  {
    path: "/artist/:artist",
    name: "artist",
    component: ArtistDetails,
    // props: true,
    // beforeEnter: (to, from, next) => {
    //   if (to.params.artist) {
    //     next(); // <-- everything good, proceed
    //   } else {
    //     next({ path: "*" }); // <-- redirect to setup
    //   }
    // },
    meta: { middleware: [guest] },
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    meta: { middleware: [guest] },
  },
  {
    path: "/cart",
    name: "CartPage",
    component: CartPage,
    meta: { middleware: [guest] },
  },
  {
    path: "/art-categories",
    name: "ArtCategories",
    component: ArtCategories,
    meta: { middleware: [guest] },
    props: true,
  },
  {
    path: "/return-policy",
    name: "ReturnPolicy",
    component: ReturnPolicy,
    meta: { middleware: [guest] },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { middleware: [guest] },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { middleware: [guest] },
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
    meta: { middleware: [guest] },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: { middleware: [guest] },
  },
  {
    path: "/artist-archive/",
    name: "Artist Archive",
    component: ArtistArchive,
    meta: { middleware: [guest] },
  },
  {
    path: "/contact-us/",
    name: "Contact",
    component: Contact,
    meta: { middleware: [guest] },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("../views/FAQ.vue"),
    meta: { middleware: [guest] },
  },
  {
    path: "/order",
    name: "Order",
    meta: { middleware: [guest] },
    component: Order,
  },
  // {
  //   path: "/orders",
  //   name: "MyOrders",
  //   meta: { middleware: [auth] },
  //   component: MyOrders,
  // },
  {
    path: "/my-account",
    name: "MyAccount",
    meta: { middleware: [auth] },
    component: MyAccount,
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    meta: { middleware: [auth] },
    component: ThankYou,
    props: {
      header: true,
      content: true,
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    meta: { middleware: [guest] },
    component: Checkout,
  },
  { path: "*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
