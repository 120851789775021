import { getError } from "@/utils/helpers";
import HomepageFieldsService from "@/services/HomepageFieldsService";

export const namespaced = true;

function setFields(commit, response) {
  commit("SET_FIELD", response.data);
  commit("SET_HOMESTORAGE", response.data);
  commit("SET_LOADING", false);
  commit("SET_SRC", "");
}

export const state = {
  field: [],
  loading: false,
  error: null,
  src: "",
  homestorage: JSON.parse(localStorage.getItem("homestorage")),
};

export const mutations = {
  SET_FIELD(state, field) {
    state.field = field;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SRC(state) {
    const storage = process.env.VUE_APP_API_URL;
    if (storage) {
      state.src = storage + "/storage/";
    }
  },
  SET_HOMESTORAGE(state, homestorage) {
    state.homestorage = localStorage.setItem(
      "homestorage",
      JSON.stringify(homestorage)
    );
  },
};

export const actions = {
  getField({ commit }) {
    commit("SET_LOADING", true);
    HomepageFieldsService.getField()
      .then((response) => {
        setFields(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
};

export const getters = {
  field: (state) => {
    return state.field;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
  src: (state) => {
    return state.src;
  },
  homestorage: (state) => {
    return state.homestorage;
  },
};
