<template>
  <v-card :rounded="false" flat>
    <v-layout column>
      <v-form ref="form" @submit.prevent="submit">
        <v-flex>
          <v-text-field
            label="Email*"
            v-model="email"
            type="email"
            :rules="emailRules"
            required
            :disabled="loading"
            :error="errors.hasOwnProperty('email')"
            :messages="errors.email"
          ></v-text-field>
        </v-flex>

        <v-flex class="aa-icons-eye">
          <v-text-field
            label="Password*"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            @click:append="show1 = !show1"
            :disabled="loading"
            :error="errors.hasOwnProperty('password')"
            :messages="errors.password"
          ></v-text-field>
        </v-flex>

        <v-flex>
          <v-text-field
            label="First Name*"
            v-model="first_name"
            :rules="[rules.required]"
            maxlength="20"
            required
            :disabled="loading"
          ></v-text-field>
        </v-flex>

        <v-flex>
          <v-text-field
            label="Last Name*"
            v-model="last_name"
            :rules="[rules.required]"
            maxlength="20"
            required
            :disabled="loading"
          ></v-text-field>
        </v-flex>

        <v-flex>
          <v-text-field
            label="Shipping Address*"
            v-model="shipping_address"
            :rules="[rules.required]"
            required
            :disabled="loading"
          ></v-text-field>
        </v-flex>

        <v-layout justify-center>
          <button
            class="btn btn-primary arrow"
            type="submit"
            :disabled="loading"
            :loading="loading"
          >
            Register
          </button>
        </v-layout>
      </v-form>
    </v-layout>
  </v-card>
</template>

<script>
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import CartService from "@/services/CartService";

export default {
  mounted: function() {
    this.$store.dispatch("loader/showLoader", {
      loading: false,
    });
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const payload = {
          email: this.email,
          password: this.password,
          first_name: this.first_name,
          last_name: this.last_name,
          shipping_address: this.shipping_address,
        };

        const payload_login = {
          email: this.email,
          password: this.password,
        };

        try {
          await AuthService.registerUser(payload);
          await AuthService.login(payload_login);

          const authUser = await this.$store.dispatch("auth/getAuthUser");
          if (authUser) {
            this.$store.dispatch("auth/setGuest", { value: "isNotGuest" });

            if (localStorage.guestCart) {
              const localCartData = JSON.parse(
                localStorage.getItem("guestCart") || "[]"
              );
              let localCartItems = localCartData.cart_items;

              localCartItems.forEach((el) => {
                let item = el;

                delete item["local_id"];
                delete item["id"];
                delete item["image"];

                const response = CartService.addCartItem(item);
                console.log(response);
              });
              localStorage.removeItem("guestCart");
            }

            localStorage.setItem("guest", "isNotGuest");
            await this.$store.dispatch("cart/getCart");

            !this.isFlagPath && this.$router.push({ path: "/my-account" });
          } else {
            const error = Error(
              "Unable to fetch user after login, check your API settings."
            );
            error.name = "Fetch User";
            throw error;
          }

          this.$emit("upDataAuth");
        } catch (error) {
          this.loading = false;
          this.errors = getError(error);
        }
      }
    },
  },
  props: ["isFlagPath"],
  data: () => {
    return {
      errors: {},
      tab: null,
      loading: false,
      password: "",
      email: null,
      first_name: "",
      last_name: "",
      shipping_address: "",
      show1: false,
      rememberMe: false,
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      rules: {
        required: (value) => !!value || "This field is required",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
    };
  },
};
</script>
