<template>
  <v-app :dark="false" :light="false" id="inspire">
    <v-main class="main-bg">
      <v-container fill-height fluid>
        <slot />
      </v-container>
    </v-main>
    <aa-footer />
    <Snackbar />
  </v-app>
</template>

<script>
import Footer from "../components/Footer/Footer.vue";
import Snackbar from "../components/Snackbar.vue";

export default {
  components: { "aa-footer": Footer, Snackbar },
};
</script>

<style lang="scss">
.main-bg {
  //   background-color: #00242c;
  background-color: var(--v-primary-darken1);
}
</style>
