import * as API from "@/services/API";

export default {
  getArtistsOurField() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get("/our-artists-get-fields")
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getArtistsOur(page) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/our-artists-get-artists?page=${page}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getArtistsOurWithSearch(page, search, options) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/our-artists-get-artists?page=${page}${search}${options}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
