<template>
  <div class="aa-header__toolbar-account">
    <v-menu offset-y :nudge-top="-10">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon :ripple="false" color="light" v-bind="attrs" v-on="on">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15 13.75C17.7614 13.75 20 11.5114 20 8.75C20 5.98858 17.7614 3.75 15 3.75C12.2386 3.75 10 5.98858 10 8.75C10 11.5114 12.2386 13.75 15 13.75ZM15 26.25C19.8325 26.25 23.75 24.0114 23.75 21.25C23.75 18.4886 19.8325 16.25 15 16.25C10.1675 16.25 6.25 18.4886 6.25 21.25C6.25 24.0114 10.1675 26.25 15 26.25Z"
              fill="black"
            />
          </svg>
        </v-btn>
      </template>
      <v-list class="aa-header__toolbar-account-list">
        <v-list-item to="/my-account">
          <v-list-item-title>My Account</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapGetters("auth", ["loading", "error", "authUser"]),
  },
  methods: {
    logout: function () {
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("cart/getCart");
    },
  },
};
</script>


<style lang="scss">
@import "./style-account.scss";
</style>