<template>
  <div class="aa-cart">
    <v-btn
      class="aa-cart__btn"
      @click="openDialog"
      icon
      :ripple="false"
      color="light"
      to="/cart"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="27"
        viewBox="0 0 26 27"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.5 1.5C0.5 0.982233 0.919733 0.5625 1.4375 0.5625H3.9375C5.83598 0.5625 7.375 2.10152 7.375 4L20.5 4C22.941 4 24.9734 5.74919 25.4123 8.0625H5.5V6.50096C5.5 6.50064 5.5 6.50032 5.5 6.5V4C5.5 3.13705 4.80044 2.4375 3.9375 2.4375H1.4375C0.919733 2.4375 0.5 2.01777 0.5 1.5ZM9.875 26.5C10.9105 26.5 11.75 25.6605 11.75 24.625C11.75 23.5895 10.9105 22.75 9.875 22.75C8.83947 22.75 8 23.5895 8 24.625C8 25.6605 8.83947 26.5 9.875 26.5ZM21.125 26.5C22.1605 26.5 23 25.6605 23 24.625C23 23.5895 22.1605 22.75 21.125 22.75C20.0895 22.75 19.25 23.5895 19.25 24.625C19.25 25.6605 20.0895 26.5 21.125 26.5ZM5.5 9.9375H25.5V15.25C25.5 18.0114 23.2614 20.25 20.5 20.25H10.5C7.73858 20.25 5.5 18.0114 5.5 15.25V9.9375Z"
          fill="#000000"
        />
      </svg>

      <span class="aa-cart__num" v-if="cartNumber"> {{ cartNumber }}</span>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Cart",
  data: function() {
    return {
      cartOpen: false,
      cartItemsLength: 0,
      loading: {},
    };
  },
  mounted: async function() {
    if (
      !this.authUser &&
      window.localStorage.getItem("guest") !== "isNotGuest"
    ) {
      localStorage.setItem("guest", "isGuest");
    } else {
      window.localStorage.setItem("guest", "isNotGuest");
    }

    try {
      await this.$store.dispatch("cart/getCart");
      if (this.authUser) {
        await this.$store.dispatch("auth/getAuthUser");
      }
    } catch (err) {
      console.log(err);
    }

    this.$eventHub.$on("upDataCart", () => {
      this.closeModal();
    });

    if (this.authUser) {
      this.cartItemsLength = await this.cart.total_items;
    } else {
      let localData = JSON.parse(localStorage.getItem("guestCart") || "[]");

      this.cartItemsLength = await localData.total_items;
    }
  },
  watch: {
    $route() {
      if (!this.authUser) {
        let localData = JSON.parse(localStorage.getItem("guestCart") || "[]");

        this.cartItemsLength = localData.total_items;
      } else {
        this.cartItemsLength = this.cartNumber;
      }
    },
  },
  methods: {
    closeModal: function() {
      let localData = JSON.parse(localStorage.getItem("guestCart"));
      this.cartItemsLength = localData ? localData.total_items : 0;
    },
    openDialog: function() {
      if (
        this.cart &&
        this.cart.cart_items &&
        this.cart.cart_items.length > 0
      ) {
        this.cartOpen = true;
      }
    },
    closeDialog: function() {
      this.cartOpen = false;
    },
  },
  computed: {
    ...mapGetters("cart", ["error", "cart"]),
    ...mapGetters("auth", ["error", "authUser"]),
    cartText: function() {
      if (!this.cart || !this.cart.cart_items) {
        return "cart empty";
      }

      if (this.cart.cart_items.length === 0) {
        return "cart empty";
      }

      let text = "cart (";
      text += this.cart.total_items;

      text += ")";
      return text;
    },
    cartNumber: function() {
      if (this.authUser) {
        return this.cart.total_items;
      }
      return this.cartItemsLength;
    },
  },
  filters: {
    currency: function(value) {
      if (!value) return "-";
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
  },
};
</script>

<style lang="scss">
@import "./style-cart.scss";
</style>
