import { getError } from "@/utils/helpers";
import HomepageCategoriesService from "@/services/HomepageCategoriesService";

export const namespaced = true;

function setCategories(commit, response) {
  commit("SET_CATEGORIES", response.data.reverse());
  commit("SET_HOMECATEGORIES", response.data);
  commit("SET_LOADING", false);
  commit("SET_SRC", "");
}

export const state = {
  categories: [],
  loading: false,
  error: null,
  src: "",
  homecategories: JSON.parse(localStorage.getItem("homecategories")),
};

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SRC(state) {
    const storage = process.env.VUE_APP_API_URL;
    if (storage) {
      state.src = storage + "/storage/";
    }
  },
  SET_HOMECATEGORIES(state, homecategories) {
    state.homecategories = localStorage.setItem(
      "homecategories",
      JSON.stringify(homecategories)
    );
  },
};

export const actions = {
  getCategory({ commit }) {
    commit("SET_LOADING", true);
    HomepageCategoriesService.getCategories()
      .then((response) => {
        setCategories(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
};

export const getters = {
  categories: (state) => {
    return state.categories;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
  src: (state) => {
    return state.src;
  },
  homecategories: (state) => {
    return state.homecategories;
  },
};
