<template>
  <main class="aa-home">
    <Banner />
    <DoCard />
    <PrintingCard />
    <FreshArtSlider />
  </main>
</template>

<script>
import Banner from "@/components/Banners/MainBanner";
import DoCard from "@/components/Cards/DoCard";
import PrintingCard from "@/components/Cards/PrintingCard";
import FreshArtSlider from "@/components/Slider/FreshArtSlider";

export default {
  name: "Home",
  components: {
    Banner,
    PrintingCard,
    FreshArtSlider,
    DoCard,
  },
  data: () => {
    return {
      fields: [],
      products: [],
    };
  },
  created: async function() {
    this.$store.dispatch("homepage/getField");
    this.$store.dispatch("homeCategories/getCategory");
    this.$store.dispatch("homeSlider/getSliders");

    if (localStorage.getItem("guest", "isGuest")) {
      this.$store
        .dispatch("auth/getAuthUser")
        .then((user) => {
          if (user) {
            this.$store.dispatch("auth/setGuest", { value: "isNotGuest" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  methods: {},
};
</script>

<style></style>
