<template>
  <div class="aa-cart-list__item">
    <div class="aa-cart-list__item-img">
      <v-img
        :src="
          cartItem.image
            ? cartItem.image.preview_image.path
            : '/images/placehold.webp'
        "
        height="180px"
        width="200px"
        lazy-src="/images/placehold.webp"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>

    <div class="aa-cart-list__item-product">
      <h3 v-text="getLabelForItem(cartItem)"></h3>
      <p v-text="getSubtitleForMedia(cartItem)"></p>
      <p>
        {{ cartItem.quantity }} item<span v-if="cartItem.quantity > 1">s</span>
      </p>
      <p>{{ cartItem.item_total | currency }}</p>
    </div>

    <div class="aa-cart-list__item-quantity">
      <div class="aa-cart-list__item-quantity-wrap quantity-toggle">
        <button @click="decrement()">&#45;</button>
        <input
          type="number"
          min="1"
          max="99"
          maxlength="2"
          oninput="if(this.value < 1){this.value = 1}else if(this.value > 99){this.value = 99};"
          v-model="quantity"
          @input="isQuantity(cartItem)"
        />
        <button @click="increment()">&#43;</button>
      </div>
    </div>

    <div class="aa-cart-list__item-total">
      <h3>{{ cartItem.total | currency }}</h3>
      <div class="aa-cart-list__item-total-btn">
        <v-btn icon @click="editCartItem(cartItem)">
          <i class="aa-icon-edit-rectangle aa-icon"></i>
        </v-btn>
        <v-btn
          :loading="loading[cartItem.id]"
          icon
          @click="deleteCartItem(cartItem)"
        >
          <i class="aa-icon aa-icon-trash"></i>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CartService from "@/services/CartService";
import ConfigService from "@/services/ConfigService";
import { mapGetters } from "vuex";

export default {
  name: "CartList",
  data: function() {
    return {
      quantity: 1,
      loading: {},
      medias: [],
    };
  },
  props: ["cartItem"],
  computed: {
    ...mapGetters("auth", ["error", "authUser"]),
  },
  mounted: async function() {
    this.medias = await ConfigService.getMedias();
    this.quantity = await this.cartItem.quantity;
  },
  methods: {
    isQuantity: async function(item) {
      if (this.authUser) {
        const data = {
          quantity: this.quantity,
          item_total: item.item_total,
          total: this.quantity * item.item_total,
          product_total: this.quantity * item.item_total,
          discount_total: item.discount_total,
          print_options: item.print_options,
        };
        try {
          const response = await CartService.updateCartItem(item.id, data);
          console.log(response);
          this.$store.dispatch("cart/getCart");
        } catch (err) {
          console.error(err);
        }
      } else {
        let localData = JSON.parse(localStorage.getItem("guestCart") || "[]");
        let localItems = localData.cart_items;
        let allItems, currItem, currIndex;
        let dataLength = 0,
          dataProductTotal = 0,
          dataGrandTotal = 0,
          dataDiscountTotal = 0;

        currItem = localItems.filter(function(obj, index) {
          currIndex;
          if (obj.local_id === item.local_id) {
            currIndex = index;
          }
          return obj.local_id === item.local_id;
        });

        allItems = localItems.filter(function(obj) {
          return obj.local_id !== item.local_id;
        });

        currItem[0].quantity = this.quantity;
        currItem[0].item_total = item.item_total;
        currItem[0].total = this.quantity * item.item_total;
        currItem[0].product_total = this.quantity * item.item_total;
        allItems.splice(currIndex, 0, currItem[0]);

        if (localData.length !== 0) {
          allItems.forEach(function(e) {
            dataLength += e.quantity;
            dataProductTotal += e.product_total;
            dataGrandTotal += e.total;
            dataDiscountTotal += e.discount_total;
          });
        }

        localData.cart_items = allItems;
        localData.total_items = dataLength;
        localData.product_total = dataProductTotal;
        localData.grand_total = dataGrandTotal;
        localData.discount_total = dataDiscountTotal;

        localStorage.setItem("guestCart", JSON.stringify(localData));
        this.$emit("events", false);
        this.$eventHub.$emit("upDataCart", true);
      }
    },
    increment() {
      if (this.quantity >= 99) {
        return false;
      }
      this.quantity++;
      this.$nextTick(function() {
        this.isQuantity(this.cartItem);
      });
    },
    decrement() {
      if (this.quantity <= 1) {
        return false;
      } else {
        this.quantity--;
        this.$nextTick(function() {
          this.isQuantity(this.cartItem);
        });
      }
    },
    editCartItem: function(item) {
      if (this.authUser) {
        this.$router.push({
          path: `/order?cart_item_id=${item.id}&mode=edit`,
        });
      } else {
        this.$router.push({
          name: "Order",
          query: {
            cart_item_id: item.local_id,
            mode: "edit",
          },
        });
      }
    },
    getLabelForItem: function(item) {
      let label = "";
      if (item.image) {
        label = item.image.name ? item.image.name : "Untitled";
      } else if (item.product) {
        label = item.product.name;
      }
      return label;
    },
    getSubtitleForMedia: function(item) {
      let subtitle = "";

      if (item.image) {
        const mediaValue = item.print_options.media;
        if (mediaValue && item.print_options) {
          const selectedMedia = this.medias.find((media) => {
            return media.value === mediaValue;
          });

          if (selectedMedia) {
            subtitle += selectedMedia.text;
          }
        }

        if (item.print_options.printHeight && item.print_options.printWidth) {
          subtitle += `, ${item.print_options.printWidth}x${item.print_options.printHeight}in`;
        }

        if (item.print_options.frame) {
          subtitle += ", framed";
        }

        if (item.print_options.mat) {
          subtitle += ", matted";
        }
      } else if (item.product) {
        subtitle = item.description;
      }

      return subtitle;
    },
    deleteCartItem: async function(item) {
      if (this.authUser) {
        this.$set(this.loading, item.id, true);
        await CartService.deleteCartItem(item.id);
        this.$store.dispatch("cart/getCart");
        this.$delete(this.loading, item.id);
      } else {
        let localData = JSON.parse(localStorage.getItem("guestCart") || "[]");
        let localItems = localData.cart_items;
        let dataLength = 0,
          dataProductTotal = 0,
          dataGrandTotal = 0,
          dataDiscountTotal = 0;
        if (localData.length == 0) {
          this.$router.go(0);
          return false;
        }
        localItems = localItems.filter(function(obj) {
          return obj.local_id !== item.local_id;
        });
        if (localData.length !== 0) {
          localItems.forEach(function(e) {
            dataLength += e.quantity;
            dataProductTotal += e.product_total;
            dataGrandTotal += e.total;
            dataDiscountTotal += e.discount_total;
          });
        }
        localData.cart_items = localItems;
        localData.total_items = dataLength;
        localData.product_total = dataProductTotal;
        localData.grand_total = dataGrandTotal;
        localData.discount_total = dataDiscountTotal;
        localStorage.setItem("guestCart", JSON.stringify(localData));
        if (localData.cart_items.length == 0) {
          localStorage.removeItem("guestCart");
        }
        this.$eventHub.$emit("upDataCart", true);
        this.$emit("events", false);
      }
    },
  },
  filters: {
    currency: function(value) {
      if (!value) return "-";
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style-cart-list.scss";
</style>
