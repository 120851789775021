<template>
  <div class="aa-my-account__information">
    <div class="aa-my-account__information__overlay">
      <div class="aa-my-account__information__overlay-wrapp"></div>
    </div>
    <v-form v-model="formValid" ref="form" lazy-validation>
      <div class="aa-my-account__information-account overlay__form">
        <h4>My Account</h4>
        <v-text-field
          type="text"
          label="Email"
          v-model="email"
          hide-details="auto"
          :rules="validationRules.required"
          :error="errors.hasOwnProperty('email')"
          :messages="errors.email"
        />

        <v-text-field
          type="text"
          label="Full name"
          v-model="name"
          hide-details="auto"
          :rules="validationRules.required"
        />

        <v-text-field
          hide-spin-buttons
          label="Markup percentage"
          type="number"
          v-model.number="markup"
          hide-details="auto"
          oninput="if(this.value < 0){this.value = 0}else if(this.value > 100000){this.value = 100000};"
          :rules="validationRules.markup"
        />

        <v-textarea
          v-if="this.filterRules !== 'customer'"
          label="About"
          v-model="description"
          rows="7"
          auto-grow
        ></v-textarea>
      </div>

      <div class="aa-my-account__information-shipping overlay__form">
        <h4>Default Shipping Address</h4>

        <v-text-field
          type="text"
          label="Address line 1"
          v-model="shipping_address_line_1"
          hide-details="auto"
          :rules="validationRules.required"
        />

        <v-text-field
          type="text"
          label="Address line 2"
          v-model="shipping_address_line_2"
          hide-details="auto"
        />

        <v-text-field
          type="text"
          label="City"
          v-model="shipping_address_city"
          class="custom__line"
          hide-details="auto"
          :rules="validationRules.required"
        />

        <v-text-field
          type="text"
          label="State"
          v-model="shipping_address_state"
          class="custom__line"
          hide-details="auto"
          :rules="validationRules.required"
        />

        <v-text-field
          type="number"
          label="ZIP Code"
          v-model.number="shipping_address_postal_code"
          class="custom__line"
          hide-details="auto"
          :rules="validationRules.required"
        />

        <!-- <v-text-field
          type="text"
          label="Country"
          v-model="shipping_address_country"
          class="custom__line"
          hide-details="auto"
          :rules="validationRules.required"
        ></v-text-field> -->
      </div>

      <div class="aa-my-account__information-billing">
        <h4>Default Billing Address</h4>
        <v-checkbox
          v-model="shipping_is_billing"
          label="Same as Shipping"
          hide-details="auto"
          color="red"
          :ripple="false"
        ></v-checkbox>

        <div class="overlay__form" v-if="!shipping_is_billing">
          <v-text-field
            type="text"
            label="Address line 1"
            v-model="billing_address_line_1"
            hide-details="auto"
            :rules="validationRules.requiredIfNotSame"
          />

          <v-text-field
            type="text"
            label="Address line 2"
            v-model="billing_address_line_2"
            hide-details="auto"
          />

          <v-text-field
            type="text"
            label="City"
            class="custom__line"
            v-model="billing_address_city"
            hide-details="auto"
            :rules="validationRules.requiredIfNotSame"
          />

          <v-text-field
            type="text"
            label="State"
            class="custom__line"
            v-model="billing_address_state"
            hide-details="auto"
            :rules="validationRules.requiredIfNotSame"
          />

          <v-text-field
            type="text"
            label="ZIP Code"
            class="custom__line"
            v-model="billing_address_postal_code"
            hide-details="auto"
            :rules="validationRules.requiredIfNotSame"
          />

          <!-- <v-text-field
            type="text"
            label="Country"
            class="custom__line"
            v-model="billing_address_country"
            hide-details="auto"
            :rules="validationRules.requiredIfNotSame"
          /> -->
        </div>
      </div>
      <div class="aa-my-account__information-buttons">
        <button class="btn-secondary arrow">Cancel</button>
        <v-btn class="btn btn-primary arrow" @click="submit" :loading="loading">
          Update
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";

export default {
  mounted: function() {
    this.$store.dispatch("auth/getAuthUser").then((user) => {
      this.name = user.name;
      this.email = user.email;
      this.markup = user.markup;
      this.description = user.description;
      this.shipping_address_line_1 = user.shipping_address_line_1;
      this.shipping_address_line_2 = user.shipping_address_line_2;
      this.shipping_address_city = user.shipping_address_city;
      this.shipping_address_state = user.shipping_address_state;
      this.shipping_address_postal_code = user.shipping_address_postal_code;
      // this.shipping_address_country = user.shipping_address_country;

      this.billing_address_line_1 = user.billing_address_line_1;
      this.billing_address_line_2 = user.billing_address_line_2;
      this.billing_address_city = user.billing_address_city;
      this.billing_address_state = user.billing_address_state;
      this.billing_address_postal_code = user.billing_address_postal_code;
      // this.billing_address_country = user.billing_address_country;
    });

    this.$store.dispatch("loader/showLoader", {
      loading: false,
    });
  },
  data: () => {
    return {
      formValid: true,
      loading: false,
      name: null,
      email: null,
      description: null,
      markup: null,
      errors: {},
      shipping_is_billing: false,
      shipping_address_line_1: null,
      shipping_address_line_2: null,
      shipping_address_city: null,
      shipping_address_state: null,
      shipping_address_postal_code: null,
      // shipping_address_country: null,
      billing_address_line_1: null,
      billing_address_line_2: null,
      billing_address_city: null,
      billing_address_state: null,
      billing_address_postal_code: null,
      // billing_address_country: null,
    };
  },
  methods: {
    submit: function() {
      const valid = this.$refs.form.validate();
      if (valid) {
        // submit the actual form
        AuthService.updateProfile({
          name: this.name,
          email: this.email,
          description: this.description,
          markup: this.markup,
          shipping_is_billing: this.shipping_is_billing,
          shipping_address_line_1: this.shipping_address_line_1,
          shipping_address_line_2: this.shipping_address_line_2,
          shipping_address_city: this.shipping_address_city,
          shipping_address_state: this.shipping_address_state,
          shipping_address_postal_code: this.shipping_address_postal_code,
          // shipping_address_country: this.shipping_address_country,
          billing_address_line_1: this.billing_address_line_1,
          billing_address_line_2: this.billing_address_line_2,
          billing_address_city: this.billing_address_city,
          billing_address_state: this.billing_address_state,
          billing_address_postal_code: this.billing_address_postal_code,
          // billing_address_country: this.billing_address_country,
        })
          .then(() => {
            this.$store.dispatch("snackbar/showSnack", {
              text: "Profile Updated Successfully.",
              color: "success",
              timeout: 2000,
            });
            this.$store.dispatch("auth/getAuthUser");
          })
          .catch((err) => {
            this.errors = getError(err);
            this.$store.dispatch("snackbar/showSnack", {
              text:
                "There was an error updating profile. Please try again later or contact support.",
              color: "error",
              timeout: 3500,
            });
          });
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["error", "authUser"]),
    validationRules: function() {
      const rules = {};
      rules.required = [(v) => !!v || "This field is required."];
      rules.markup = [
        (v) => v > 9 || "The markup percentage cannot be less than 10%.",
      ];
      rules.requiredIfNotSame = [];
      if (!this.shipping_is_billing) {
        rules.requiredIfNotSame.push((v) => !!v || "This field is required.");
      }
      return rules;
    },
    filterRules: function() {
      let isUser;
      if (!this.authUser.admin && !this.authUser.artist) {
        isUser = "customer";
      }
      if (this.authUser.admin && !this.authUser.artist) {
        isUser = "admin";
      }
      if (!this.authUser.admin && this.authUser.artist) {
        isUser = "artist";
      }

      return isUser;
    },
  },
};
</script>

<style lang="scss">
@import "./info-style.scss";
</style>
