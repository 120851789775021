<template>
  <v-dialog :value="true" persistent scrollable max-width="400px">
    <v-card>
      <v-card-title>Confirm</v-card-title>
      <v-card-text style="max-height: 500px">
        <slot v-if="!loading"></slot>
        <v-container v-if="loading">
          <v-row justify="center" class="mb-2">
            {{ loadingText }}
          </v-row>
          <v-row justify="center">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="btn-secondary arrow"
          text
          @click="closeDialog"
          :disabled="loading"
          :loading="loading"
        >
          {{ cancelLabel ? cancelLabel : "Cancel" }}
        </v-btn>
        <v-btn
          class="btn btn-primary arrow"
          @click="confirmDialog"
          :disabled="loading"
          :loading="loading"
        >
          {{ acceptLabel ? acceptLabel : "Accept" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    acceptColor: String,
    acceptLabel: String,
    cancelColor: String,
    cancelLabel: String,
    loadingText: String,
    loading: Boolean,
  },
  methods: {
    closeDialog: function () {
      this.$emit("close-dialog");
    },
    confirmDialog: function () {
      this.$emit("confirm-dialog");
    },
  },
};
</script>
