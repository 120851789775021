<template>
  <div class="aa-portfolio__gallery-list-sell">
    <v-checkbox
      v-model="checkbox"
      @change="isChecked()"
      label="Sell in market"
      class="pa-0 ma-0"
      color="red"
      :ripple="false"
      :disabled="this.loading"
      :loading="this.loading"
      hide-details="auto"
    ></v-checkbox>
  </div>
</template>

<script>
import ImageService from "@/services/ImageService";

export default {
  data: () => ({
    checkbox: false,
    id: null,
    loading: false,
  }),
  props: {
    items: Object,
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(e) {
        this.checkbox = e.sell;
      },
    },
  },
  methods: {
    isChecked: async function () {
      this.id = this.items.id;
      this.loading = true;
      try {
        const payload = {
          sale_in_market: this.checkbox,
        };

        const response = await ImageService.putImage(this.id, payload);
        this.$emit("updated-image");
        console.log(response);
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./sell-in-market-style.scss";
</style>