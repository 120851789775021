import * as API from "@/services/API";

export default {
  getFrames() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/config/frames`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getMats() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/config/mats`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getMedias() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/config/medias`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getMountings() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/config/mountings`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPresets() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/config/presets`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPricing() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/config/pricing`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPrintSizes() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/config/printSizes`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getVarnishes() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/config/varnishes`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getWraps() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/config/wraps`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
