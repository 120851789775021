import * as API from "@/services/API";

export default {
  getArtistImages(page, slug, search) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/get-artist-images?page=${page}&slug=${slug}${search}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getArtistInfo(slug) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/get-artist/${slug}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
