import { getError } from "@/utils/helpers";
import HomepageSliderService from "@/services/HomepageSliderService";

export const namespaced = true;

function setSlider(commit, response) {
  commit("SET_SLIDER", response.data);
  commit("SET_LOADING", false);
}

export const state = {
  slider: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_SLIDER(state, slider) {
    state.slider = slider;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getSliders({ commit }) {
    commit("SET_LOADING", true);
    HomepageSliderService.getSliders()
      .then((response) => {
        setSlider(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
};

export const getters = {
  slider: (state) => {
    return state.slider;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
