import * as API from "@/services/API";

export default {
  getOrders() {
    return API.apiClient.get("/orders");
  },
  getOrder(id) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/orders/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addOrder(payload) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .post(`/orders`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getOrderPagination(id) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/account-orders?page=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
