<template>
  <header app class="header aa-header" :class="{ active: isActive }">
    <v-container>
      <div class="aa-header__wrapper">
        <router-link :to="{ name: 'Home' }" class="aa-header__logo">
          <img class="logo" src="/images/AA-logo.svg" />
        </router-link>

        <nav class="aa-header__menu">
          <ul>
            <li v-for="item in links" :key="item.id">
              <router-link active-class="active" :to="item.url">
                {{ item.name }}
                <span
                  v-show="item.subitem"
                  class="v-icon mdi mdi-menu-down "
                ></span>
              </router-link>

              <div class="aa-header__menu-subitem" v-if="item.subitem">
                <router-link
                  :to="subitem.url"
                  v-for="subitem in item.subitem"
                  :key="subitem.id"
                >
                  {{ subitem.name }}
                </router-link>
              </div>
            </li>

            <li v-if="!authUser">
              <router-link
                active-class="active"
                alt="Archival Arts Logo"
                :to="{ path: '/login' }"
              >
                Log In
              </router-link>
            </li>
          </ul>
          <router-link
            v-if="!authUser"
            class="btn btn-primary arrow"
            to="/login"
            >Join Us
          </router-link>
        </nav>

        <div class="aa-header__toolbar" :class="{ is_login: authUser }">
          <Cart />
          <AccountMenu v-show="authUser" />

          <router-link
            v-if="!authUser"
            to="/login"
            class="btn btn-primary arrow"
            >Join Us
          </router-link>

          <button class="aa-header__burger" @click="burgerActive">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                opacity="0.4"
                cx="4.33333"
                cy="4.33325"
                r="4"
                fill="#9D2235"
              />
              <circle cx="17.6667" cy="17.6665" r="4" fill="#9D2235" />
              <circle
                opacity="0.4"
                cx="4.33333"
                cy="17.6665"
                r="4"
                fill="#9D2235"
              />
              <circle
                opacity="0.4"
                cx="17.6667"
                cy="4.33325"
                r="4"
                fill="#9D2235"
              />
            </svg>
          </button>
        </div>
      </div>
    </v-container>
  </header>
</template>

<script>
// import MainMenu from "@/components/MainMenu.vue";
import Cart from "@/components/ToolsBar/Cart.vue";
import AccountMenu from "@/components/ToolsBar/AccountMenu.vue";

import { mapGetters } from "vuex";

export default {
  name: "Header",
  data: () => ({
    isActive: false,
    noScroll: document.querySelector("html").classList.remove("on-scroll"),
    value: 1,
    links: [
      { url: "/about", name: "About Us" },
      {
        url: "/art-categories",
        name: "Art Categories",
        subitem: [{ url: "/categories", name: "All Categories" }],
      },
      { url: "/artist-archive", name: "Artist Archive" },
      { url: "/contact-us", name: "Contact" },
    ],
  }),
  created: async function() {
    if (this.authUser) {
      await this.$store.dispatch("auth/getAuthUser");
    }
  },
  components: { Cart, AccountMenu },
  computed: {
    ...mapGetters("auth", ["loading", "error", "authUser"]),
  },
  methods: {
    burgerActive: async function() {
      this.isActive = !this.isActive;
      document.querySelector("html").classList.toggle("on-scroll");
    },
    burgerResize: function() {
      const width = document.documentElement.clientWidth;
      if (width > 1200) {
        this.noScroll;
        this.isActive = false;
      }
    },
  },
  async mounted() {
    await window.addEventListener("resize", this.burgerResize);
  },
  watch: {
    $route() {
      this.isActive = false;
      document.querySelector("html").classList.remove("on-scroll");
    },
  },
  // updated() {
  //   console.log(this.$route);
  // },
};
</script>

<style lang="scss">
@import "./header-style.scss";
</style>
