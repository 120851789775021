<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <button
        :disabled="flagMat"
        v-bind="attrs"
        v-on="on"
        :class="{
          'v-btn v-btn--is-elevated v-btn--fab v-btn--has-bg v-btn--round theme--light v-size--x-small': true,
          'ma-1': true,
          'mat-button': true,
          selected: selectedMat === mat,
        }"
        :style="{ 'background-color': mat.color }"
        @click="$emit('selected-mat', mat)"
      ></button>
    </template>
    <span>
      {{ mat.text }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "MatSwatch",
  props: {
    selectedMat: Object,
    mat: Object,
    flagMat: Boolean,
  },
};
</script>

<style lang="scss">
.mat-button {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2) !important;
  border: 2px solid transparent !important;

  &.selected {
    border-color: var(--red) !important;
  }
}

.preview-color {
  width: 200px;
  height: 200px;
}
</style>
