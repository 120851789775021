import * as API from "@/services/API";

export default {
  getCategories() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/get-categories`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCategoriesField() {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/categories-fields`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
