import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  treeShake: true,
  customVariables: ["~/scss/variables.scss"],
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    light: true,
    // disable: true,
    themes: {
      light: {
        primary: "#000",
        red: "#9D2235",
        burgundy: "#5E121E",
        gold: "#EAAA00",
        dark: "#000000",
        silver: "#484848",
        light: "#ffffff",
        bg: "#F7DD99",
        error: "#F8BAC3",
      },
    },
  },
});
