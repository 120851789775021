<template>
  <div class="aa-privacy-policy">
    <v-container>
      <div class="aa-privacy-policy__wrap">
        <h1>Privacy Policy</h1>
        <div v-if="this.field" v-html="this.field.content"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
import PrivacyPolicy from "@/services/PrivacyPolicy.js";

export default {
  data: () => {
    return {
      field: null,
    };
  },
  created: async function() {
    this.field = await PrivacyPolicy.getPrivacyPolicyField();

    if (this.field) {
      this.$store.dispatch("loader/showLoader", {
        loading: false,
      });
    }
  },
};
</script>

<style lang="scss">
@import "./style-privacy-policy.scss";
</style>
