import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Vuex from "vuex";
import store from "./store";
import VueTour from "vue-tour";
import moment from "moment";
import "./filters";
import { func } from "./func.js";

//  scss
import "./assets/scss/stylesheet.scss";
require("vue-tour/dist/vue-tour.css");
Vue.prototype.$func = func;
Vue.prototype.$eventHub = new Vue();

//layouts
import Default from "./layouts/Default.vue";
import Guest from "./layouts/Guest.vue";

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY");
  }
});

Vue.component("default-layout", Default);
Vue.component("guest-layout", Guest);
Vue.use(VueTour);

Vue.use(Vuex);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
