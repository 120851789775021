<template>
  <div class="aa-cart-page ">
    <v-container>
      <div class="aa-cart-page__wrap">
        <h1 v-show="this.cartFilterLength || this.localDataItems">
          Your Cart
        </h1>

        <div
          v-show="!this.cartFilterLength && !this.localDataItems"
          class="aa-cart-page__empty"
        >
          <h2>Your cart is empty!</h2>
          <router-link to="/art-categories" class="btn btn-primary arrow"
            >Create new order
          </router-link>
        </div>

        <div
          class="aa-cart-list__head"
          v-show="this.cartFilterLength || this.localDataItems"
        >
          <div>Product</div>
          <div>Quantity</div>
          <div>Total</div>
        </div>

        <div
          class="aa-cart-list"
          v-show="this.cartFilterLength || this.localDataItems"
        >
          <CartList
            @events="isFlag"
            v-for="item in cart.cart_items || this.localDataItems"
            :cartItem="item"
            :key="item.id"
          />
        </div>

        <div
          class="aa-cart-list__footer"
          v-show="this.cartFilterLength || this.localDataItems"
        >
          <div class="aa-cart-list__footer-subtotal">
            Subtotal
            <h3 class="ma-0">
              {{
                cart.grand_total
                  ? cart.grand_total
                  : this.localDataSubtotal | currency
              }}
            </h3>
          </div>
          <div class="aa-cart-list__footer-btn">
            <router-link class="btn-secondary arrow" to="/art-categories"
              >Back to products</router-link
            >
            <router-link class="btn btn-primary arrow" to="/checkout"
              >Checkout</router-link
            >
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CartList from "@/components/ToolsBar/CartList.vue";

export default {
  name: "Cart",
  components: { CartList },
  data: function() {
    return {
      cartOpen: false,
      localDataItems: [],
      localDataSubtotal: "",
      // flagLocalStorage: localStorage.guestCart,
    };
  },
  mounted: async function() {
    await this.$store.dispatch("cart/getCart");

    const dataGuest = await JSON.parse(
      localStorage.getItem("guestCart") || "[]"
    );

    this.localDataItems = dataGuest.cart_items;
    this.localDataSubtotal = await this.cartSubtotal;
  },
  methods: {
    isFlag: async function() {
      if (!this.authUser) {
        const dataGuest = await JSON.parse(
          localStorage.getItem("guestCart") || "[]"
        );

        this.localDataItems = dataGuest.cart_items;
        this.localDataSubtotal = dataGuest.product_total;
      }
    },
    openDialog: function() {
      if (
        this.cart &&
        this.cart.cart_items &&
        this.cart.cart_items.length > 0
      ) {
        this.cartOpen = true;
      }
    },
    closeDialog: function() {
      this.cartOpen = false;
    },
  },
  computed: {
    ...mapGetters("cart", ["error", "cart"]),
    ...mapGetters("auth", ["error", "authUser"]),
    cartSubtotal: function() {
      if (!this.authUser) {
        const dataGuest = JSON.parse(localStorage.getItem("guestCart") || "[]");

        return dataGuest.length !== 0 ? dataGuest.product_total : 0;
      }
      return this.cart.product_total ? this.cart.product_total : 0;
    },
    cartFilterLength: function() {
      if (this.cart.cart_items && this.cart.cart_items.length == 0) {
        console.log("cart empty");
        return this.cart.cart_items.length;
      }

      return this.authUser ? true : false;
    },
    cartLoader: function() {
      console.log(this.cart);
      if (this.cart) {
        this.$store.dispatch("loader/showLoader", {
          loading: false,
        });
        return false;
      }
      return this.cart;
    },
  },
  filters: {
    currency: function(value) {
      if (!value) return "-";
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
  },
};
</script>

<style lang="scss">
@import "./style-cart-page.scss";
</style>
