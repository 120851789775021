<template>
  <v-dialog :value="!null" max-width="600px" m-0 scrollable persistent>
    <div class="aa-my-account__orders-id-popup">
      <v-layout
        align-center
        justify-space-between
        class="aa-my-account__orders-id-title"
      >
        <h3>
          Invoice
          <span
            >#{{
              ordersIdData.invoice_id ? ordersIdData.invoice_id : "Empty ID"
            }}</span
          >
        </h3>
        <span>{{ dateFormatted(ordersIdData.created_at) }}</span>
      </v-layout>
      <div
        v-for="itemId in ordersIdData.order_items"
        :key="itemId.id"
        class="aa-my-account__orders-id-item"
      >
        <h3>
          {{ itemId.image ? itemId.image.name : "Empty Name" }}
        </h3>
        <ul>
          <li><span>Quantity</span> {{ itemId.quantity }}</li>
          <li><span>Size</span>{{ orderSize(itemId.print_options) }}</li>
          <li><span>Unit Price</span> {{ itemId.item_total }}</li>
        </ul>
      </div>
      <div class="aa-my-account__orders-id-total">
        <ul>
          <li>
            <span>Total Items</span>
            <p>{{ this.totalItems }}</p>
          </li>
          <li>
            <span>Subtotal</span>
            <h3>${{ ordersIdData.total }}</h3>
          </li>
        </ul>
      </div>
      <div class="aa-my-account__orders-id-btn">
        <button class="btn-secondary arrow" @click="closeDialog" text>
          Close
        </button>
        <v-btn class="btn btn-primary arrow"> Re-Order </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    totalItems: null,
  }),
  props: {
    ordersIdData: Object,
  },
  // watch: {
  //   ordersIdData: function (newVal) {
  //     console.log(newVal);
  //   },
  // },
  methods: {
    closeDialog: function () {
      this.$emit("close-orders-id");
      this.totalItems = null;
    },
    dateFormatted(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    orderSize(size) {
      let printWidth = size.printWidth;
      let printHeight = size.printHeight;

      return printWidth + "x" + printHeight + "in";
    },
    totalItem() {
      let arr = [];
      let arrSun = 0;

      this.ordersIdData.order_items.forEach((value) => {
        if (value.quantity) {
          arr.push(value.quantity);
        }
      });

      if (arr.length) {
        arrSun = arr.reduce((a, b) => {
          return (parseFloat(a) || 0) + (parseFloat(b) || 0);
        });
      }

      this.totalItems = arrSun;
    },
  },
  mounted: function () {
    this.totalItem();
  },
};
</script>

<style lang="scss">
@import "./style-ordersid.scss";
</style>
