<template>
  <v-dialog
    :value="selectedImage.available_dimensions"
    max-width="600px"
    @click:outside="closeDialog"
  >
    <div class="aa-my-account__download">
      <h3>Enter File Size or Choose Preset Below</h3>

      <v-alert v-if="overSize" color="red" text align="center"
        >Sizes over 3000px or 10in will cause stretching.
      </v-alert>

      <h4>Custom Size</h4>

      <div class="aa-my-account__download-head">
        <v-text-field
          type="number"
          placeholder="Width"
          v-model.number="width"
          oninput="if(this.value < 0) this.value = 0;"
          solo
          flat
          hide-details="auto"
        />

        <v-text-field
          type="number"
          placeholder="Height"
          v-model.number="height"
          oninput="if(this.value < 0) this.value = 0;"
          solo
          flat
          hide-details="auto"
        />

        <v-select
          :items="['in', 'px']"
          v-model="unit"
          placeholder="Unit"
          solo
          flat
          hide-details="auto"
          :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>

        <v-btn
          :loading="loading['custom']"
          :disabled="!(width && height && unit)"
          @click="downloadCustom"
          color="red"
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M40.3343 31.1667V18.3333C40.3343 14.2832 37.0511 11 33.001 11H28.1121C26.5254 11 24.9815 10.4854 23.7121 9.53333L20.2899 6.96667C19.0205 6.01464 17.4766 5.5 15.8899 5.5H11.001C6.95091 5.5 3.66766 8.78325 3.66766 12.8333V31.1667C3.66766 35.2168 6.95091 38.5 11.001 38.5H33.001C37.0511 38.5 40.3343 35.2168 40.3343 31.1667Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.625 25.9817C20.5395 25.9174 20.4574 25.8463 20.3795 25.7685L17.4723 22.8612C16.9353 22.3242 16.0647 22.3242 15.5277 22.8612C14.9908 23.3982 14.9908 24.2688 15.5277 24.8058L18.435 27.713C20.4039 29.6819 23.5961 29.6819 25.565 27.713L28.4723 24.8058C29.0092 24.2688 29.0092 23.3982 28.4723 22.8612C27.9353 22.3242 27.0647 22.3242 26.5277 22.8612L23.6205 25.7685C23.5426 25.8463 23.4605 25.9174 23.375 25.9817V18.3335C23.375 17.5741 22.7594 16.9585 22 16.9585C21.2406 16.9585 20.625 17.5741 20.625 18.3335V25.9817Z"
              fill="white"
            />
          </svg>
        </v-btn>
      </div>

      <v-list class="aa-my-account__download-list">
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="(item, i) in selectedImage.available_dimensions"
            :key="i"
            @click="downloadImage(item)"
          >
            <span
              v-text="item.width + ' x ' + item.height + ' ' + item.unit"
            ></span>

            <button>
              <i class="aa-icon-download" v-if="!loading[item.value]"></i>

              <div v-if="loading[item.value]">
                <v-progress-circular indeterminate color="red" />
              </div>
            </button>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="aa-my-account__download-btn">
        <button class="btn-secondary arrow" @click="closeDialog">Close</button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ImageService from "@/services/ImageService";
export default {
  name: "DownloadDialog",
  props: {
    selectedImage: Object,
  },
  computed: {
    overSize: function () {
      if (this.unit === "in" && (this.width > 10 || this.height > 10)) {
        return true;
      }

      if (this.unit === "px" && (this.width > 3000 || this.height > 3000)) {
        return true;
      }

      return false;
    },
  },
  methods: {
    closeDialog: function () {
      this.$emit("close-download-dialog");
    },
    downloadImage: async function (size) {
      try {
        this.$set(this.loading, size.value, true);
        const response = await ImageService.downloadImage(
          this.selectedImage.id,
          {
            width: size.width,
            height: size.height,
            unit: size.unit,
          }
        );
        window.location.href = response.url;
        this.$delete(this.loading, size.value);
      } catch (error) {
        this.$delete(this.loading, size.value);
        console.error(error);
        this.$store.dispatch("snackbar/showSnack", {
          text: "There was an error downloading the image. It may have been too large.",
          color: "error",
          timeout: 2000,
        });
      }
    },
    downloadCustom: async function () {
      try {
        this.$set(this.loading, "custom", true);
        const response = await ImageService.downloadImage(
          this.selectedImage.id,
          {
            width: this.width,
            height: this.height,
            unit: this.unit,
          }
        );
        window.location.href = response.url;
        this.$delete(this.loading, "custom");
      } catch (error) {
        this.$delete(this.loading, "custom");
        console.error(error);
        this.$store.dispatch("snackbar/showSnack", {
          text: "There was an error downloading the image. It may have been too large.",
          color: "error",
          timeout: 2000,
        });
      }
    },
  },
  watch: {
    width: function (value) {
      // since we're locked into an aspect ratio, set the height when the width is changed
      let decimals = 2;

      if (this.unit === "px") {
        decimals = 0;
      }

      this.height = parseFloat(
        parseFloat(value * this.selectedImage.height_ratio).toFixed(decimals)
      );
    },
    height: function (value) {
      // since we're locked into an aspect ratio, set the width when the height is changed
      let decimals = 2;

      if (this.unit === "px") {
        decimals = 0;
      }

      this.width = parseFloat(
        parseFloat(value * this.selectedImage.ratio).toFixed(decimals)
      );
    },
    unit: function () {
      this.height = null;
      this.width = null;
    },
  },
  data: () => {
    return {
      selectedItem: null,
      downloadOptions: [],
      loading: {},
      width: null,
      height: null,
      unit: "px",
    };
  },
};
</script>

<style lang="scss">
@import "./style-download.scss";
</style>
