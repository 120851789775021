<template>
  <v-card :rounded="false" flat>
    <v-form ref="form" lazy-validation @submit.prevent="submit">
      <div>
        <v-text-field
          label="Email"
          v-model="email"
          :rules="loginEmailRules"
          required
          :error="errors.hasOwnProperty('email')"
          :messages="errors.email"
          :disabled="loading"
          type="email"
        ></v-text-field>
      </div>
      <div class="aa-icons-eye">
        <v-text-field
          label="Password"
          v-model="password"
          :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          @click:append="show1 = !show1"
          :error="errors.hasOwnProperty('password')"
          :messages="errors.password"
          :disabled="loading"
        >
        </v-text-field>
      </div>

      <div>
        <button
          :class="{ loading: loading }"
          :disabled="loading"
          type="submit"
          class="btn btn-primary arrow"
        >
          Submit
        </button>
      </div>
    </v-form>
    <p class="aa-login-form__reset">
      Forgot password? <button @click="resetForm">Reset</button>
    </p>
  </v-card>
</template>

<script>
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import CartService from "@/services/CartService";

export default {
  name: "LoginForm",
  methods: {
    resetForm() {
      const el = document.querySelector('[href="#forgot-password"]');
      el.click();

      const currentQuery = this.$route.query;
      if (currentQuery.p !== "forgot_password") {
        this.$router.push({ query: { p: "forgot_password" } });
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$emit("formLoad", this.loading);
        const payload = {
          email: this.email,
          password: this.password,
        };
        this.error = null;
        try {
          await AuthService.login(payload);

          const authUser = await this.$store.dispatch("auth/getAuthUser");
          if (authUser) {
            this.$store.dispatch("auth/setGuest", { value: "isNotGuest" });

            if (localStorage.guestCart) {
              const localCartData = JSON.parse(
                localStorage.getItem("guestCart") || "[]"
              );

              let localCartItems = localCartData.cart_items;

              localCartItems.forEach((el) => {
                let item = el;

                delete item["local_id"];
                delete item["id"];
                delete item["image"];

                const response = CartService.addCartItem(item);
                console.log(response);
              });

              localStorage.removeItem("guestCart");
            }

            localStorage.setItem("guest", "isNotGuest");
            await this.$store.dispatch("cart/getCart");

            !this.isFlagPath && this.$router.push({ path: "/my-account" });
          } else {
            const error = Error(
              "Unable to fetch user after login, check your API settings."
            );
            error.name = "Fetch User";
            this.loading = false;
            this.$emit("formLoad", this.loading);
            throw error;
          }

          this.$emit("upDataAuth");
        } catch (error) {
          this.loading = false;
          this.$emit("formLoad", this.loading);
          console.log(this.loading);
          this.errors = getError(error);
        }
      }
    },
  },
  data: () => {
    return {
      tab: null,
      loading: false,
      password: "",
      email: null,
      show1: false,
      errors: {},
      requiredRules: [(v) => !!v || "This field is required."],
      loginEmailRules: [
        (v) => !!v || "This field is required.",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      emailRules: [
        (v) => !!v || "This field is required.",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      rules: {
        required: (value) => !!value || "This field is required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
    };
  },
  props: ["someProps", "isFlagPath"],
  async mounted() {
    if (this.loading) this.doSomething();

    this.$store
      .dispatch("loader/showLoader", {
        loading: false,
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss">
@import "./login-style.scss";
</style>
