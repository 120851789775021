<template>
  <div class="aa-all-categories container-single">
    <div class="aa-all-categories__banner">
      <v-container>
        <div class="aa-all-categories__banner-wrapper">
          <h1>
            {{ this.fields.title }}
          </h1>
          <p class="ma-0" v-html="this.fields.description"></p>
        </div>
      </v-container>
    </div>
    <div class="aa-all-categories__list container-out">
      <v-container>
        <div class="aa-all-categories__list-wrapper">
          <div class="aa-all-categories__list-heading">
            <h2 class="ma-0">Categories</h2>
            <a class="btn btn-primary__circle" href="/art-categories"
              >View All</a
            >
          </div>
          <div class="aa-all-categories__list-body">
            <div
              v-for="(image, index) in this.categories"
              :key="image.id"
              class="aa-all-categories__list-item"
              :class="index == 2 ? 'active' : ''"
            >
              <router-link
                :to="{
                  name: 'ArtCategories',
                  query: { subject: image.id },
                }"
              >
                <v-img
                  :src="$func.src(image.path)"
                  :lazy-src="$func.src(image.path)"
                  aspect-ratio="1.4"
                  height="260"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="red"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <h3>{{ image.name }}</h3>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import CategoriesFieldService from "@/services/CategoriesFieldService.js";

export default {
  data: () => {
    return {
      categories: {},
      fields: {},
      heading: {
        title: "All Categories",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus sollicitudin nunc dignissim urna, sed. Cursus facilisis ut vulputate justo.",
      },
    };
  },
  created: async function() {
    try {
      this.categories = await CategoriesFieldService.getCategories();
      this.fields = await CategoriesFieldService.getCategoriesField();

      this.$store.dispatch("loader/showLoader", {
        loading: this.categories && this.fields ? false : true,
      });
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style lang="scss">
@import "./style-categories.scss";
</style>
