import * as API from "@/services/API";

export default {
  getField() {
    return API.apiClient.get("/artist-sales");
  },
  getCurrentPage(page) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/artist-sales?page=${page}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
