<template>
  <div class="aa-checkout__toolbar">
    <v-dialog :value="dialogOpen" persistent scrollable max-width="800px">
      <div class="aa-checkout__toolbar-popup">
        <h3>Add Additional Products</h3>

        <v-form ref="form" lazy-validation>
          <div class="aa-checkout__toolbar-input">
            <v-autocomplete
              v-model="product"
              :items="productOptions"
              placeholder="Product"
              :rules="validationRules.required"
              solo
              hide-details="auto"
              flat
            ></v-autocomplete>

            <v-text-field
              name="description"
              placeholder="Description"
              v-model="description"
              hint="Enter a description to include on the invoice."
              :rules="validationRules.description"
              solo
              hide-details="auto"
              flat
            ></v-text-field>
          </div>

          <div
            class="aa-checkout__toolbar-popup-calc"
            v-if="calculationType && calculationType !== 'manual'"
          >
            <h4>
              Price Calculation
            </h4>
            <p v-if="productPrice">
              Product Unit Price: {{ productPrice | currency }}
            </p>
            <p v-if="calculationType === 'scan'">
              Min Price: {{ this.pricing.scanMin | currency }}
            </p>
            <p v-if="calculationType === 'scan'">
              Max Price: {{ this.pricing.scanMax | currency }}
            </p>
            <p v-if="calculationType === 'scanNoColor'">
              Min Price: {{ (this.pricing.scanMin / 2) | currency }}
            </p>
            <p v-if="calculationType === 'scanNoColor'">
              Max Price: {{ (this.pricing.scanMax / 2) | currency }}
            </p>

            <div
              class="aa-checkout__toolbar-popup-calc-wrap"
              v-if="
                calculationType === 'borderedSqIn' ||
                  calculationType === 'sqIn' ||
                  calculationType === 'scan' ||
                  calculationType === 'scanNoColor' ||
                  calculationType === 'cubicIn'
              "
            >
              <div
                v-if="
                  calculationType === 'borderedSqIn' ||
                    calculationType === 'sqIn' ||
                    calculationType === 'scan' ||
                    calculationType === 'scanNoColor' ||
                    calculationType === 'cubicIn'
                "
              >
                <span>Width</span>
                <v-text-field
                  type="number"
                  min="0"
                  v-model="width"
                  :rules="validationRules.width"
                  placeholder="Width"
                  solo
                  hide-details="auto"
                  hide-spin-buttons
                ></v-text-field>
              </div>
              <div
                v-if="
                  calculationType === 'borderedSqIn' ||
                    calculationType === 'sqIn' ||
                    calculationType === 'scan' ||
                    calculationType === 'scanNoColor' ||
                    calculationType === 'cubicIn'
                "
              >
                <span>Height</span>
                <v-text-field
                  type="number"
                  min="0"
                  v-model="height"
                  placeholder="Height"
                  required
                  :rules="validationRules.height"
                  solo
                  hide-details="auto"
                  flat
                  hide-spin-buttons
                ></v-text-field>
              </div>
              <div v-if="calculationType === 'cubicIn'">
                <span>Depth</span>
                <v-text-field
                  type="number"
                  min="0"
                  v-model="depth"
                  required
                  :rules="validationRules.depth"
                  placeholder="Depth"
                  solo
                  hide-details="auto"
                  flat
                  hide-spin-buttons
                ></v-text-field>
              </div>
              <div v-if="calculationType === 'borderedSqIn'">
                <span>Border</span>
                <v-text-field
                  type="number"
                  min="0"
                  v-model="border"
                  required
                  :rules="validationRules.border"
                  placeholder="Border"
                  solo
                  hide-details="auto"
                  flat
                  hide-spin-buttons
                ></v-text-field>
              </div>
            </div>
          </div>

          <div class="aa-checkout__toolbar-popup-price">
            <div>
              <h3>Price Per</h3>
              <div>
                <span v-if="!canSetPrice">
                  {{ pricePer | currency }}
                </span>
                <v-text-field
                  v-if="canSetPrice"
                  type="number"
                  placeholder="Price Per"
                  oninput="if(this.value < 1){this.value = 1}else if(this.value > 999999){this.value = 99999};"
                  hide-details="auto"
                  min="0"
                  v-model.number="manualPricePer"
                  reverse
                  solo
                  flat
                  :rules="validationRules.pricePer"
                  hide-spin-buttons
                ></v-text-field>
              </div>
            </div>

            <div>
              <h3>
                Quantity
              </h3>
              <v-text-field
                type="number"
                oninput="if(this.value < 1){this.value = 1}else if(this.value > 99999){this.value = 99999};"
                placeholder="Quantity"
                min="1"
                v-model.number="quantity"
                reverse
                hide-details="auto"
                solo
                flat
                :rules="validationRules.required"
                hide-spin-buttons
              ></v-text-field>
            </div>

            <div v-if="discountTotal">
              <h4>Product Total</h4>
              <span>{{ productTotal | currency }}</span>
            </div>

            <div v-if="discountTotal">
              <h4>Discount ({{ this.pricing * 100 }}%)</h4>
              <span>-{{ discountTotal | currency }}</span>
            </div>

            <div>
              <h3>Total</h3>
              <span>{{ totalPrice | currency }}</span>
            </div>
          </div>
        </v-form>

        <div class="aa-checkout__toolbar-popup-buttons">
          <v-btn text @click="closeDialog" class="btn-secondary arrow">
            Close
          </v-btn>
          <v-btn
            :disabled="!canAddToCart"
            class="btn btn-primary arrow"
            @click="submit"
          >
            Add Item
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <div class="aa-checkout__toolbar-buttons">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="openDialog"
            v-bind="attrs"
            v-on="on"
            icon
            :ripple="false"
            color="light"
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M10.6665 4.00008C10.6665 2.15913 12.1589 0.666748 13.9998 0.666748C15.8408 0.666748 17.3332 2.15913 17.3332 4.00008V24.0001C17.3332 25.841 15.8408 27.3334 13.9998 27.3334C12.1589 27.3334 10.6665 25.841 10.6665 24.0001V4.00008Z"
                fill="black"
              />
              <path
                d="M23.9998 10.6667C25.8408 10.6667 27.3332 12.1591 27.3332 14.0001C27.3332 15.841 25.8408 17.3334 23.9998 17.3334L3.99984 17.3334C2.15889 17.3334 0.666504 15.841 0.666504 14.0001C0.666504 12.1591 2.15889 10.6667 3.99984 10.6667L23.9998 10.6667Z"
                fill="black"
              />
            </svg>
          </v-btn>
        </template>
        <span>Add Additional Products</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="openDialog"
            v-bind="attrs"
            v-on="on"
            :to="`/order?mode=calculator`"
            icon
            :ripple="false"
            color="light"
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.75 12.75V0.25H1.5C0.8125 0.25 0.25 0.8125 0.25 1.5V12.75H12.75Z"
                fill="#000000"
                opacity="0.4"
              />
              <path
                d="M12.75 15.25H0.25V26.5C0.25 27.1875 0.8125 27.75 1.5 27.75H12.75V15.25Z"
                fill="#000000"
                opacity="0.4"
              />
              <path
                d="M26.5 0.25H15.25V15.25V19V27.75H26.5C27.1875 27.75 27.75 27.1875 27.75 26.5V19V15.25V1.5C27.75 0.8125 27.1875 0.25 26.5 0.25Z"
                fill="black"
              />
              <path
                d="M4 6.5H9"
                stroke="white"
                stroke-width="1.25"
                stroke-miterlimit="10"
              />
              <path
                d="M4 21.5H9"
                stroke="white"
                stroke-width="1.25"
                stroke-miterlimit="10"
              />
              <path
                d="M6.5 4V9"
                stroke="white"
                stroke-width="1.25"
                stroke-miterlimit="10"
              />
              <path
                d="M19.5628 13.1874C20.1841 13.1874 20.6878 12.6838 20.6878 12.0624C20.6878 11.4411 20.1841 10.9374 19.5628 10.9374C18.9415 10.9374 18.4378 11.4411 18.4378 12.0624C18.4378 12.6838 18.9415 13.1874 19.5628 13.1874Z"
                style="fill: white;"
              />
              <path
                d="M23.4376 17.0626C24.0589 17.0626 24.5626 16.5589 24.5626 15.9376C24.5626 15.3163 24.0589 14.8126 23.4376 14.8126C22.8163 14.8126 22.3126 15.3163 22.3126 15.9376C22.3126 16.5589 22.8163 17.0626 23.4376 17.0626Z"
                style="fill: white;"
              />
              <path
                d="M23.8126 11.6876L19.1876 16.3126"
                stroke="white"
                stroke-width="1.25"
                stroke-miterlimit="10"
              />
            </svg>
          </v-btn>
        </template>
        <span>Price Calculator</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import ConfigService from "@/services/ConfigService";
import CartService from "@/services/CartService";
import ProductService from "@/services/ProductService";
import { mapGetters } from "vuex";

export default {
  name: "AdditionalProductsDialog",
  data: () => {
    return {
      formValid: true,
      dialogOpen: false,
      products: [],
      description: "",
      quantity: 1,
      product: null,
      manualPricePer: 0,
      width: null,
      height: null,
      depth: null,
      border: null,
    };
  },
  created: async function() {
    if (this.authUser) {
      await this.$store.dispatch("auth/getAuthUser");
    }
  },
  mounted: async function() {
    if (this.authUser) {
      this.pricing = await ConfigService.getPricing();
      this.products = await ProductService.getProducts();
    }
  },
  methods: {
    resetForm: function() {
      this.formValid = true;
      this.description = "";
      this.quantity = 1;
      this.product = null;
      this.manualPricePer = 0;
      this.width = null;
      this.height = null;
      this.depth = null;
      this.border = null;
    },
    openDialog: function() {
      this.dialogOpen = true;
    },
    closeDialog: function() {
      this.dialogOpen = false;
    },
    submit: async function() {
      const valid = this.$refs.form.validate();
      if (valid) {
        const data = {
          quantity: this.quantity,
          total: this.totalPrice,
          item_total: this.pricePer,
          discount_total: this.discountTotal,
          product_total: this.productTotal,
          product_id: this.product.id,
          description: this.description,
        };

        try {
          const response = await CartService.addCartItem(data);
          console.log(response);
          this.$store.dispatch("cart/getCart");
          this.$store.dispatch("snackbar/showSnack", {
            text: "Item added to cart successfully.",
            color: "success",
            timeout: 2000,
          });
          // do we close the dialog or not?
          this.resetForm();
          this.dialogOpen = false;
        } catch (err) {
          console.error(err);
          this.$store.dispatch("snackbar/showSnack", {
            text:
              "There was an error adding item to cart. Please try again later.",
            color: "error",
            timeout: 3500,
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    pricePer: function() {
      if (!this.productPrice) {
        return null;
      }
      if (this.calculationType === "sqIn" && this.sqIn) {
        return this.sqIn * this.productPrice;
      } else if (this.calculationType === "cubicIn" && this.cubicIn) {
        return this.cubicIn * this.productPrice;
      } else if (this.calculationType === "borderedSqIn" && this.borderedSqIn) {
        return this.borderedSqIn * this.productPrice;
      } else if (this.calculationType === "flat") {
        return this.productPrice;
      } else if (this.calculationType === "manual") {
        return this.manualPricePer;
      } else if (this.calculationType === "scan") {
        if (!this.sqIn) {
          return null;
        }
        const total = this.sqIn * this.productPrice;
        if (total < this.pricing.scanMin) {
          return this.pricing.scanMin;
        }
        if (total > this.pricing.scanMax) {
          return this.pricing.scanMax;
        }

        return total;
      } else if (this.calculationType === "scanNoColor") {
        if (!this.sqIn) {
          return null;
        }
        const total = this.sqIn * this.productPrice;
        if (total < this.pricing.scanMin / 2) {
          return this.pricing.scanMin / 2;
        }
        if (total > this.pricing.scanMax / 2) {
          return this.pricing.scanMax / 2;
        }

        return total;
      }

      return null;
    },
    productPrice: function() {
      if (!(this.product && this.product.unit_price)) {
        return null;
      }
      return this.product.unit_price;
    },
    calculationType: function() {
      if (!this.product) {
        return null;
      }

      if (!this.product.calculation_type) {
        return null;
      }

      return this.product.calculation_type;
    },
    discountTotal: function() {
      let discountTotal = 0;

      if (this.authUser) {
        discountTotal = this.productTotal * this.pricing;
      }

      return discountTotal;
    },
    productTotal: function() {
      if (!this.product) {
        return null;
      }
      const total = this.quantity * this.pricePer;
      return total;
    },
    totalPrice: function() {
      if (!this.productTotal) {
        return null;
      }
      return this.productTotal - this.discountTotal;
    },
    productOptions: function() {
      return this.products.map((product) => {
        return {
          text: product.name,
          value: product,
        };
      });
    },
    canAddToCart: function() {
      return this.product;
    },
    canSetPrice: function() {
      return this.calculationType === "manual";
    },
    sqIn: function() {
      if (this.width && this.height) {
        return this.width * this.height;
      }

      return null;
    },
    cubicIn: function() {
      if (this.width && this.height && this.depth) {
        return this.width * this.height * this.depth;
      }

      return null;
    },
    borderedSqIn: function() {
      if (this.width && this.height && this.border) {
        return (this.width + this.border) * (this.height + this.border);
      }

      return null;
    },
    validationRules: function() {
      const rules = {};

      rules.height = [];
      rules.width = [];
      rules.depth = [];
      rules.border = [];
      rules.pricePer = [];

      rules.required = [(v) => !!v || "This field is required."];
      if (this.calculationType === "sqIn") {
        rules.height = [(v) => !!v || "Height is required."];
        rules.width = [(v) => !!v || "Width is required."];
      } else if (this.calculationType === "cubicIn") {
        rules.height = [(v) => !!v || "Height is required."];
        rules.width = [(v) => !!v || "Width is required."];
        rules.depth = [(v) => !!v || "Depth is required."];
      } else if (this.calculationType === "borderedSqIn") {
        rules.height = [(v) => !!v || "Height is required."];
        rules.width = [(v) => !!v || "Width is required."];
        rules.border = [
          (v) =>
            (v > 0 && v <= 6) || "The border must be between 1 and 6 inches.",
        ];
      } else if (this.calculationType === "manual") {
        rules.pricePer = [
          (v) => !!v || "You must enter a price for each item.",
        ];
      }
      return rules;
    },
  },
  watch: {
    width: function(value) {
      // if (this.calculationType === 'sqIn') {
      //   if (this.height && value) {
      //     this.pricePer =
      //   }
      // } else if (this.calculationType === 'cubicIn') {
      // } else if (this.calculationType === 'borderedSqIn') {
      // } else if (this.calculationType === 'manual') {
      // }
      // when the width changes, we need to make sure that we re-calculate the price
      console.log(value);
    },
    height: function(value) {
      // when the height changes, we need to make sure that we re-calculate the price
      console.log(value);
    },
    depth: function(value) {
      // when the depth changes, we need to make sure that we re-calculate the price
      console.log(value);
    },
    border: function(value) {
      // when the border changes, we need to make sure that we re-calculate the price
      console.log(value);
    },
  },
};
</script>

<style lang="scss">
@import "./style-additional-products.scss";
</style>
