<template>
  <div name="loader" class="aa-preloader">
    <div v-show="this.loading" class="aa-preloader__wrap">
      <v-row class="fill-height pa-md-16" align="center" justify="center">
        <v-progress-circular indeterminate color="red" :size="100" :width="10"
          >Loading</v-progress-circular
        >
      </v-row>
      <div class="aa-preloader__overlay"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted: function() {
    // this.$nextTick(function() {
    //   this.loading = true;
    // });

    // this.$store.subscribe((mutation, state) => {
    //   if (state.loader) {
    //     this.loading = false;
    //   }

    //   if (mutation.type === "loader/SHOW_MESSAGE" && !state.loader) {
    //     this.loading = state.loader.loading;
    //   }
    // });

    this.loading = true;

    this.$store.watch(
      (state) => state.loader,
      (loader) => {
        this.loading = loader.loading;
      }
    );

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  watch: {
    $route() {
      this.loading = true;
      this.$store.subscribe((mutation, state) => {
        if (state.loader) {
          this.loading = false;
        }

        if (mutation.type === "loader/SHOW_MESSAGE" && !state.loader) {
          this.loading = state.loader.loading;
        }
      });
    },
  },
  beforeDestroy() {
    this.$store.unsubscribe();
  },
  data() {
    return {
      show: true,
      loading: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.aa-preloader {
  &__wrap {
    position: fixed;
    height: 100vh;
    inset: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  &__overlay {
    &::after {
      content: "";
      z-index: -1;
      position: absolute;
      width: 450px;
      height: 1300px;
      right: -5%;
      top: 10%;
      display: block;
      background: linear-gradient(
        264.74deg,
        #ffc700 8.33%,
        #ff5b5b 61.23%,
        #5a88ff 110.07%
      );
      opacity: 0.6;
      filter: blur(100px);
      transform: matrix(0.75, -0.65, 0.65, 0.75, 0, 0);
    }
    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      width: 250px;
      height: 500px;
      left: 15%;
      top: 5%;
      display: block;
      background: linear-gradient(
        264.74deg,
        #ffc700 8.33%,
        #ff5b5b 61.23%,
        #5a88ff 110.07%
      );
      opacity: 0.6;
      filter: blur(100px);
      transform: matrix(0.75, -0.65, 0.65, 0.75, 0, 0);
    }
  }
}
</style>
