var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aa-artist-archive container-single"},[_c('div',{staticClass:"aa-artist-archive__banner"},[_c('v-container',[_c('div',{staticClass:"aa-artist-archive__banner-wrapper"},[_c('h1',[_vm._v(_vm._s(this.fieldTitle))]),_c('div',{staticClass:"ma-0",domProps:{"innerHTML":_vm._s(this.fieldDescription)}})])])],1),_c('div',{staticClass:"aa-artist-archive__search"},[_c('v-container',[_c('div',{staticClass:"aa-art-categories__list-search"},[_c('label',[_vm._v("Can type artist name here "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"placeholder":"Search","type":"text","disabled":this.pageLoading},domProps:{"value":(_vm.search)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$refs.searchBtn.click()},"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]),_c('div',{staticClass:"aa-art-categories__list-tools"},[_c('button',{ref:"searchBtn",staticClass:"btn btn-primary arrow",attrs:{"disabled":this.pageLoading},on:{"click":_vm.isPagination}},[_vm._v(" Search ")])])])])],1),_c('div',{staticClass:"aa-artist-archive__team container-out"},[_c('v-container',[_c('div',{staticClass:"aa-artist-archive__team-wrapper"},[_vm._l((_vm.pageData),function(artist){return _c('div',{key:artist.id,staticClass:"aa-artist-archive__team-item"},[_c('router-link',{attrs:{"to":{
              name: 'artist',
              params: { artist: _vm.slugFormat(artist.name) },
            }}},[_c('v-img',{attrs:{"src":artist.profile_image
                  ? _vm.$func.src(artist.profile_image)
                  : '/images/placehold.webp',"lazy-src":'/images/placehold.webp',"aspect-ratio":"1.4","cover":"","height":"220","width":"220"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"color":"red","align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"red"}})],1)]},proxy:true}],null,true)})],1),_c('h3',[_c('router-link',{attrs:{"to":{
                name: 'artist',
                params: { artist: _vm.slugFormat(artist.name) },
              }}},[_vm._v(" "+_vm._s(artist.name)+" ")])],1)],1)}),(this.pageLoading && this.pageData)?_c('v-row',{staticClass:"fill-height pa-md-16 aa-loading",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"red","size":100,"width":10}},[_vm._v("Loading")])],1):_vm._e()],2),(this.pageLength > 1)?_c('div',{staticClass:"v-pagination__inner"},[_c('v-pagination',{attrs:{"length":this.pageLength,"disabled":this.pageLoading,"total-visible":6,"color":"none"},on:{"input":_vm.pageCurrentPagination},model:{value:(_vm.pageCurrent),callback:function ($$v) {_vm.pageCurrent=$$v},expression:"pageCurrent"}})],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }