<template>
  <v-container>
    <div class="container-out">
      <reset-password-form />
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import ResetPasswordForm from "@/components/Form/ResetPasswordForm.vue";

export default {
  name: "ResetPassword",
  components: {
    ResetPasswordForm,
  },
  data: () => {
    return {};
  },
};
</script>
