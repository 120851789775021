<template>
  <div class="aa-login-form__reset-pass">
    <template>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="red"
      ></v-progress-linear>
    </template>

    <h3>Reset Password</h3>
    <v-form ref="form" lazy-validation @submit.prevent="submit">
      <v-text-field
        v-model="email"
        :rules="loginEmailRules"
        label="Email"
        required
        :error="errors.hasOwnProperty('email')"
        :messages="errors.email"
        :disabled="loading"
      ></v-text-field>

      <v-flex class="aa-icons-eye">
        <v-text-field
          v-model="password"
          :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Password"
          hint="At least 8 characters"
          counter
          @click:append="show1 = !show1"
          :error="errors.hasOwnProperty('password')"
          :messages="errors.password"
          :disabled="loading"
        ></v-text-field>
      </v-flex>

      <v-flex class="aa-icons-eye">
        <v-text-field
          v-model="password_confirmation"
          :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
          :rules="passwordConfirmationRules"
          :type="show2 ? 'text' : 'password'"
          name="input-10-1"
          label="Password Confirmation"
          hint="At least 8 characters"
          counter
          @click:append="show2 = !show2"
          :error="errors.hasOwnProperty('password_confirmation')"
          :messages="errors.password"
          :disabled="loading"
        ></v-text-field>
      </v-flex>

      <v-layout align-center justify-center>
        <v-btn
          class="btn btn-primary arrow"
          type="submit"
          :disabled="loading"
          :loading="loading"
          >Reset Password</v-btn
        >
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";

export default {
  name: "ResetPasswordForm",
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const payload = {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.$route.query.token,
        };
        this.error = null;
        try {
          await AuthService.resetPassword(payload);
          await AuthService.login(payload);
          const authUser = await this.$store.dispatch("auth/getAuthUser");
          if (authUser) {
            this.$store.dispatch("auth/setGuest", { value: "isNotGuest" });
            this.$router.push({ path: "/my-account" });
          } else {
            const error = Error(
              "Unable to fetch user after login, check your API settings."
            );
            error.name = "Fetch User";
            throw error;
          }
        } catch (error) {
          this.errors = getError(error);
        }
        this.loading = false;
      }
    },
  },
  data: () => {
    return {
      tab: null,
      loading: false,
      password: "",
      password_confirmation: "",
      email: null,
      show1: false,
      show2: false,
      errors: {},
      loginEmailRules: [
        (v) => !!v || "This field is required.",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      emailRules: [
        (v) => !!v || "This field is required.",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      rules: {
        required: (value) => !!value || "This field is required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
    };
  },
  computed: {
    passwordConfirmationRules: function() {
      return [
        (v) => !!v || "This field is required.",
        (v) =>
          v === this.password || "Password confirmation must match password",
        (v) => (v && v.length >= 8) || "Min 8 characters",
      ];
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/mixin";
.aa-login-form__reset-pass {
  max-width: 570px;
  padding: 50px 85px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 10px 48px -15px rgb(16 24 40 / 15%);
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  @include md-size-max {
    padding: 50px 30px;
  }
  @include sm-size-max {
    padding: 30px 15px;
  }

  h3 {
    text-align: center;
  }

  .v-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  button[type="submit"] {
    margin-top: 50px;
  }
}
</style>
