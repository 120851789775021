export const func = {
  src: (path, resize) => {
    const src = process.env.VUE_APP_API_URL;
    // let img = new Image();
    // let placehold = "/images/placehold.webp";
    let returnValue = "";
    // let flag = false;
    let returnPath = path ? path : "";
    if (resize) returnPath = returnPath.replace("/", "/resized/");

    if (src) {
      returnValue = src + "/storage/" + returnPath;
    }

    // img.src = returnValue;
    // img.onerror = function() {
    //   flag = true;
    // };

    return returnValue;
  },
};
