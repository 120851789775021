<template>
  <div class="aa-contact-us container-single">
    <v-container>
      <div class="aa-contact-us__wrapper">
        <div class="aa-contact-us__overlay"></div>
        <div class="aa-contact-us__heading">
          <h1>{{ this.fieldTitle }}</h1>
          <p v-html="this.fieldDes"></p>
        </div>
        <div class="aa-contact-us__form">
          <v-form ref="form" @submit.prevent="submit" :loading="this.loading">
            <div class="aa-contact-us__form-wrapper">
              <v-text-field
                label="First name*"
                v-model="first_name"
                required
                :rules="firstNameRules"
                :disabled="loading"
              ></v-text-field>

              <v-text-field
                label="Last name*"
                v-model="last_name"
                required
                :rules="LastNameRules"
                :disabled="loading"
              ></v-text-field>

              <v-text-field
                label="Email*"
                v-model="email"
                required
                :rules="emailRules"
                :disabled="loading"
                type="email"
              ></v-text-field>

              <v-text-field
                label="Phone*"
                v-model="phone"
                required
                :disabled="loading"
                :rules="phoneRules"
                type="number"
                min="0"
              ></v-text-field>
            </div>

            <v-textarea
              label="Message"
              v-model="message"
              rows="5"
              :disabled="loading"
            ></v-textarea>

            <v-btn
              :disabled="loading"
              type="submit"
              class="btn btn-primary arrow"
              >Submit</v-btn
            >

            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              color="red"
            ></v-progress-linear>
          </v-form>
        </div>
        <div class="aa-contact-us__callback">
          <a :href="'mailto:' + this.fieldEmail">
            <img src="/images/icons/Subtract.svg" alt="Archival Arts Email" />
            {{ this.fieldEmail }}</a
          >
          <a :href="'tel:' + phoneTelephone(this.fieldPhone)">
            <img src="/images/icons/call.svg" alt="Archival Arts Phone" />
            {{ this.fieldPhone }}</a
          >
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import ContactUsService from "@/services/ContactUsService";

export default {
  name: "Contact",
  data: () => {
    return {
      fieldTitle: "",
      fieldDes: "",
      fieldEmail: "",
      fieldPhone: "",
      first_name: "",
      last_name: "",
      email: null,
      phone: "",
      message: "",
      loading: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      firstNameRules: [(v) => !!v || "First name is required"],
      LastNameRules: [(v) => !!v || "Last name is required"],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) =>
          (v && v.length >= 8) || "Your phone must be at least 8 characters",
      ],
    };
  },
  created: async function() {
    try {
      const field = await ContactUsService.getField();

      if (field) {
        this.fieldTitle = field.first_block_title;
        this.fieldDes = field.first_block_description;
        this.fieldEmail = field.second_block_email;
        this.fieldPhone = field.second_block_phone;
      }
    } catch (err) {
      console.log(err);
    }

    this.$store.dispatch("loader/showLoader", {
      loading: false,
    });
  },
  methods: {
    phoneTelephone(val) {
      if (val) {
        return val.replace(/[^\d]/g, "");
      }
      return;
    },
    async submit() {
      const valid = await this.$refs.form.validate();

      if (valid) {
        this.loading = true;
        const payload = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone,
          message: this.message,
        };

        try {
          console.log(payload);
          const res = ContactUsService.formData(payload);
          console.log(res);

          this.$store.dispatch("snackbar/showSnack", {
            text: "Form Successful.",
            color: "success",
            timeout: 2000,
          });
        } catch (err) {
          console.error(err);

          this.$store.dispatch("snackbar/showSnack", {
            text: "This Form Has Errors.",
            color: "error",
            timeout: 2000,
          });
        }

        await this.$refs.form.reset();
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./contact.scss";
</style>
