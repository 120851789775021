<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        :disabled="flagFrame"
        :class="{
          'v-btn v-btn--is-elevated v-btn--fab v-btn--has-bg v-btn--round theme--light v-size--x-small': true,
          'ma-1': true,
          'frame-button': true,
          selected: selectedFrame === frame,
        }"
        :style="{ background: `url('${frame.swatchImage}'` }"
        @click="$emit('selected-frame', frame)"
      ></button>
    </template>
    <span>
      {{ frame.text }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "FrameSwatch",
  props: {
    selectedFrame: Object,
    frame: Object,
    flagFrame: null,
  },
};
</script>

<style lang="scss" scoped>
.frame-button {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2) !important;
  border: 2px solid transparent !important;

  &.selected {
    border-color: var(--red) !important;
  }
}

.preview-video {
  max-width: 300px;
}
</style>
