<template>
  <div class="aa-page-not-found container-out">
    <v-container>
      <div class="aa-page-not-found__wrap">
        <h1>404</h1>
        <h2>Ooops! Page not Found</h2>
        <p>
          Sorry, we can’t find the page you’re looking for
        </p>
        <router-link class="btn btn-primary arrow" to="/">
          Back to homepage
        </router-link>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style lang="scss" scoped>
@import "./style-page-not-found.scss";
</style>
