<template>
  <section class="aa-fresh-slider container-out">
    <div class="aa-fresh-slider__wrapper">
      <div class="aa-fresh-slider__content">
        <div class="aa-fresh-slider__content-wrapper">
          <h2 class="aa-fresh-slider__title">
            {{ filteredField.slider_section_title }}
          </h2>
          <p class="aa-fresh-slider__des">
            {{ filteredField.slider_section_description }}
          </p>
        </div>
      </div>
      <div class="aa-fresh-slider__buttons">
        <div class="aa-fresh-slider__buttons-wrapper">
          <router-link to="/art-categories" class="btn btn-primary__circle">
            {{ filteredField.slider_section_button_text }}
          </router-link>

          <v-flex>
            <span
              @click="$refs.prev.click()"
              class="btn btn-secondary__circle prev"
            ></span>
            <span @click="$refs.next.click()" class="btn btn-secondary__circle">
            </span>
          </v-flex>
        </div>
      </div>
      <div class="aa-fresh-slider__carousel">
        <carousel
          v-if="filteredSlider"
          :autoplaySpeed="3000"
          :autoWidth="true"
          :autoplay="true"
          :margin="30"
          :dots="false"
          :loop="true"
          :items="4"
          :nav="false"
          :responsive="{
            320: { items: 1, autoWidth: false, center: true, dots: true },
            576: { items: 2, dots: true, center: true },
            768: { items: 3, margin: 20 },
            1200: { items: 4 },
          }"
        >
          <div
            class="aa-fresh-slider__carousel-item"
            v-for="item in filteredSlider"
            :key="item.id"
          >
            <div class="aa-fresh-slider__carousel-item-img">
              <a :href="`/order?image_id=${item.id}&mode=create`">
                <img
                  :alt="item.name"
                  :title="item.name"
                  :src="
                    item.preview_image.path
                      ? item.preview_image.path
                      : '/images/placehold.webp'
                  "
                />
              </a>
            </div>
            <h4>
              <a :href="`/order?image_id=${item.id}&mode=create`">
                {{ item.name }}
              </a>
            </h4>
          </div>

          <template slot="prev">
            <span hidden ref="prev" class="prev">prev</span>
          </template>

          <template slot="next">
            <span hidden ref="next" class="next">next</span>
          </template>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from "v-owl-carousel";
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  data: () => {
    return {
      flag: false,
    };
  },
  computed: {
    ...mapGetters("homepage", ["error", "loading", "field"]),
    ...mapGetters("homeSlider", ["slider"]),
    filteredField: function() {
      if (this.field && this.field.length !== 0) {
        return this.field;
      }
      return false;
    },
    filteredLoading: function() {
      if (!this.loading || this.loading === "") {
        return this.loading;
      }
      return this.loading;
    },
    filteredSlider: function() {
      if (this.slider.length === 0) {
        return false;
      }
      return this.slider;
    },
  },
  components: { carousel },
  mounted: async function() {
    window.addEventListener("resize ", this.carousel);
  },
};
</script>

<style lang="scss">
@import "./slider-style";
</style>
