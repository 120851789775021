var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aa-type-of-paper__slider"},[_c('div',{staticClass:"aa-type-of-paper__slider-heading"},[_c('h2',[_vm._v("Similar Arts")]),_c('router-link',{staticClass:"btn btn-primary__circle",attrs:{"to":"/art-categories"}},[_vm._v(" View All ")])],1),_c('carousel',{attrs:{"autoplaySpeed":3000,"autoWidth":true,"autoplay":true,"margin":30,"dots":false,"loop":true,"items":4,"nav":false,"responsive":{
      320: { items: 1, autoWidth: false, center: true, dots: false },
      400: { items: 2, dots: true, center: true },
      768: { items: 4, margin: 20 },
      1200: { items: 4 },
    }}},[_vm._l((_vm.slides),function(slide,index){return _c('div',{key:index,staticClass:"aa-type-of-paper__slider-item"},[_c('router-link',{attrs:{"to":`order?image_id=${slide.id}&mode=create`}},[_c('img',{attrs:{"src":slide.preview_image.path
              ? slide.preview_image.path
              : '/images/placehold.webp',"alt":slide.name}})]),_c('h4',[_c('router-link',{attrs:{"to":`order?image_id=${slide.id}&mode=create`}},[_vm._v(" "+_vm._s(slide.name)+" ")])],1)],1)}),_c('template',{slot:"prev"},[_c('span',{ref:"prev",staticClass:"prev",attrs:{"hidden":""}},[_vm._v("prev")])]),_c('template',{slot:"next"},[_c('span',{ref:"next",staticClass:"next",attrs:{"hidden":""}},[_vm._v("next")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }